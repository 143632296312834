import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Box, Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material";
import BankUPI from './bankUPI';
import BankDetail from './bankDetail';
import ApiService from '../../_services/ApiService';

function BankDetailContainer(props) {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState('upi');
    const [bankDetails, setBankDetails] = useState([{ bank_name: '', account_number: '', ifsc_code: '', account_holder_name: '' }]);
    const [upiDetails, setUpiDetails] = useState([{ upi_id: '' }]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    function bankUpiDetails() {
        ApiService.get('getpostBankDetails').then(res => {
            if (res.status == 200) {
                let response = res?.data?.data
                let bankDetails = response.filter(element => element.payment_method === "BANK");
                if (bankDetails.length == 0) {
                    setUpiDetails([{ bank_name: '', account_number: '', ifsc_code: '', account_holder_name: '' }])
                } else {
                    setBankDetails(bankDetails)
                }
               
                let upiDetails = response.filter(element => element.payment_method === "UPI");
                if (upiDetails.length == 0) {
                    setUpiDetails([{ upi_id: '' }])
                } else {
                    setUpiDetails(upiDetails)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        bankUpiDetails()
    }, [])

    return (
        <Box sx={{ p: 1 }}>
            <Typography variant="h6">Fill your social account detail to earn rewards</Typography>

            <RadioGroup
                row
                value={selectedOption}
                onChange={handleOptionChange}
                sx={{ mt: 2, mb: 2 }}
            >
                <FormControlLabel value="upi" control={<Radio />} label="UPI ID" />
                <FormControlLabel value="bank" control={<Radio />} label="Bank Account Detail" />
            </RadioGroup>
            {
                selectedOption == 'upi' ?
                    (
                        <BankUPI upiDetails={upiDetails} setUpiDetails={setUpiDetails} bankUpiDetails={bankUpiDetails} />
                    )
                    :
                    (
                        <BankDetail bankDetails={bankDetails} setBankDetails={setBankDetails} bankUpiDetails={bankUpiDetails} />
                    )
            }
        </Box>
    );
}

export default BankDetailContainer;