import React,{useState,useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ThemeProvider, createTheme, useMediaQuery, useTheme } from '@mui/material';
import './customStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser } from '../../redux';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      padding:4
    },
  },
});

function Dashboard(props) {
  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   // border: '2px solid #000',
  //   boxShadow: 24,
  //   p: 4,
  // };
  // const theme = useTheme();
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const smScreen = useMediaQuery('(max-width:768px)')
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();


  // useEffect(
  //   function () {
  //     if (userData.id) {
  //       dispatch(fetchLoginUser(userData.id));
  //     }
  //   },
  //   [userData.id]
  // );


  return (
    <div className={smScreen?'p-2':'p-4'}>
    <ThemeProvider theme={theme}  >
    <Box className='dashboardbanner ' 
        sx={{
          height: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          textAlign: 'start',
          position: 'relative',
          backgroundSize:'cover',
          backgroundRepeat:'no-repeat',
          [theme.breakpoints.down('sm')]: {
            height: '250px',
          },
        }}
 >
   <Typography variant="h1" sx={{
    //  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.59), rgba(255, 255, 255, 0.59)), linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))',
     background:'#fff',
     WebkitBackgroundClip: 'text',
     WebkitTextFillColor: 'transparent',
     fontSize: '2.5rem',
     fontWeight: 'bold',
     padding:'1rem 1rem 0 1rem',
     [theme.breakpoints.down('sm')]: {
       fontSize: '2rem',
     },
   }}
   >
    <h1>Hello {userDetail.userHandle?userDetail.name:''}</h1>
   </Typography>
   <Typography variant="h6" sx={{
    //  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.59), rgba(255, 255, 255, 0.59)), linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))',
     background:'#fff',
     WebkitBackgroundClip: 'text',
     WebkitTextFillColor: 'transparent',
     fontSize: '1.25rem',
     fontWeight: '500',
     padding:'0 0 0 1rem',
     [theme.breakpoints.down('sm')]: {
       fontSize: '1rem',
     },
   }}
   >
     Welcome to our family
   </Typography>
   <Typography variant="body1" sx={{
       background:'#fff',
    //  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.59), rgba(255, 255, 255, 0.59)), linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))',
     WebkitBackgroundClip: 'text',
     WebkitTextFillColor: 'transparent',
     fontSize: '1.125',
     padding:'1rem 1rem 0 1rem',
     maxWidth: '28.125rem',
     [theme.breakpoints.down('sm')]: {
       fontSize: '1rem',
     },
   }}
   >
     Our team is dedicated to provide you with the best possible experience.We look forward to seeing what you create
   </Typography>
 </Box>

    </ThemeProvider>
   </div>

  );
}

export default Dashboard;
