import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
export async function changePassword(postData){
    const response=await axios.patch(
       process.env.REACT_APP_BASEURL + 'api/auth/change-password/',
       postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}