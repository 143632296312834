import React, { useEffect, useState } from 'react';
import { Box, Button, Card, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from '../../_services/ApiService';
import { useSelector } from 'react-redux';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

function YoutubeDetail() {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const [youtubeDetails, setYoutubeDetails] = useState([{ Name: '', Subscribers: '' }]);
    const [errors, setErrors] = useState([{}]);

    function youtubeApiDetails() {
        ApiService.get('getpostyoutubeDetails').then(res => {
            if (res.status == 200) {
                let response = res?.data?.data
                if (response.length == 0) {
                    setYoutubeDetails([{ Name: '', Subscribers: '' }])
                } else {
                    setYoutubeDetails(response)
                }
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        youtubeApiDetails()
    }, [])


    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedDetails = [...youtubeDetails];
        updatedDetails[index][name] = value;
        setYoutubeDetails(updatedDetails);
    };

    const validateFields = (index) => {
        let isValid = true;
        const fieldErrors = {};
        const detail = youtubeDetails[index];
        ['Name', 'Subscribers'].forEach(field => {
            if (!detail[field]) {
                fieldErrors[field] = `${field.replace('_', ' ')} is required`;
                isValid = false;
            }
        });

        const updatedErrors = [...errors];
        updatedErrors[index] = fieldErrors;
        setErrors(updatedErrors);

        return isValid;
    };


    const addNewyoutubeHandler = (e, index) => {
        e.preventDefault()
        if (validateFields(index)) {
            let formData = new FormData()
            const { Name, Subscribers } = youtubeDetails[index];
            const cleanedyoutubeDetails = {
                Name,
                Subscribers
            };
            Object.entries(cleanedyoutubeDetails).forEach(([key, value]) => {
                formData.append(key, value)
            });
            if (youtubeDetails[index]?.id) {
                infoSnack('youtube Account Updating...')
                ApiService.put('getpostyoutubeDetails', formData, null, youtubeDetails[index]?.id).then(res => {
                    const updatedName = res?.data?.Name || res?.data?.Subscribers;
                    if (res.status == 200) {
                        successSnack('youtube Details Updated Successfully.')
                        youtubeApiDetails()
                    } else {
                        errorSnack(updatedName)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            else {
                infoSnack('youtube Account Details Adding...')
                ApiService.post('getpostyoutubeDetails', formData).then(res => {
                    const updatedName = res?.data?.Name || res?.data?.Subscribers;
                    if (res.status == 200) {
                        successSnack('youtube Details Added Successfully.')
                        youtubeApiDetails()
                    } else {
                        errorSnack(updatedName)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    };

    const addMoreyoutube = () => setYoutubeDetails([...youtubeDetails, { Name: '', Subscribers: '' }])

    const removeyoutubeDetail = (data, index) => {
        if (data?.id) {
            infoSnack(data.Name + ' Account Deleting...')
            ApiService.delete('deleteyoutubeDetails', {}, data?.id).then(res => {
                if (res.status == 200) {
                    successSnack(res?.data?.message)
                    const updatedyoutubeDetails = youtubeDetails.filter((_, i) => i !== index);
                    if (updatedyoutubeDetails.length === 0) {
                        setYoutubeDetails([{ Name: '', Subscribers: '' }]);
                    } else {
                        setYoutubeDetails(updatedyoutubeDetails);
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setYoutubeDetails(youtubeDetails.filter((_, i) => i !== index))
        }
    };

    return (
        <Box sx={{ p: 1 }}>
            <Typography variant="h6">Fill your social account detail to earn rewards</Typography>
            <Box sx={{ p: 1 }}>
                <Card sx={{ p: 2, backgroundColor: '#f9f7f7' }}>
                    <Typography variant="subtitle1">Youtube Details</Typography>
                    {youtubeDetails.map((detail, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography>Account {index + 1}</Typography>
                                <IconButton disabled={youtubeDetails?.length === 1 && detail?.id === undefined ? true : youtubeDetails?.length > 1 ? false : false}
                                    onClick={() => removeyoutubeDetail(detail, index)}><DeleteIcon /></IconButton>
                            </Box>
                            {['Name', 'Subscribers'].map((field) => (
                                <TextField
                                    key={field}
                                    name={field}
                                    label={field.toUpperCase()}
                                    value={detail[field]}
                                    onChange={(e) => handleChange(index, e)}
                                    error={!!errors[index]?.[field]}
                                    helperText={errors[index]?.[field]}
                                    required
                                    fullWidth
                                    margin="dense"
                                />
                            ))}
                            <Box className='text-end w-100'>
                                <Button onClick={(e) => addNewyoutubeHandler(e, index)} variant="text" className='colorText' >Save youtube Detail</Button>
                            </Box>
                        </Box>
                    ))}
                    <Button disabled={youtubeDetails.length == 5} onClick={addMoreyoutube} variant="contained" className='backgroundColor borderColor'>+ ADD MORE</Button>
                </Card>
            </Box>
        </Box>
    );
}

export default YoutubeDetail;
