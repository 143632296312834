import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TextField, IconButton, InputAdornment, Box, Skeleton, Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ApiService from "../_services/ApiService";
import useSnackDispatcher from "../_helpers/reusablefunctions/snackBarComponent";
import backgroundImage from '../img/bg_image_texture3.png'
import LogoApp from '../img/Gn-Creator-logo.svg'
import SnackbarContainer from "../module/Snackbar";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { formSnackbar } from "../_services/_constants/snackbar.constant";
import './style.css';
import { setLoader } from "../redux";

function Register() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { successSnack, errorSnack, infoSnack } = useSnackDispatcher();
  const userRole = [{ value: 'nano', label: 'Nano' }, { value: 'macro', label: 'Macro' }, { value: 'micro', label: 'Micro' }]
  const [userTypeId, setUserTypeId] = useState("");
  const [form, setForm] = useState({
    name: '',
    email: '',
    contactNumber: '',
    password: '',
    c_password: '',
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [gnTerm, setGnTerm] = useState(false);
  const [gnService, setGnService] = useState(false);

  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*?&])[a-zA-Z0-9!#$@^%&?]{8,20}$/;
  const phoneRegex = /^[0-9]{10}$/;

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!form.name.trim() && form.name.length == 50) {
      tempErrors["name"] = "Please enter a valid name (only alphabets allowed)";
      isValid = false;
    }

    if (!mailformat.test(form.email) && form.email.length == 50) {
      tempErrors["email"] = "Please enter a valid email";
      isValid = false;
    }

    if (!phoneRegex.test(form.contactNumber) && form.contactNumber.length == 10) {
      tempErrors["contactNumber"] = "Please enter a valid contact number (max 10 digits)";
      isValid = false;
    }

    if (!passwordRegex.test(form.password) && form.password.length == 50) {
      tempErrors["password"] = "Password must contain 8-20 characters, at least 1 uppercase, 1 lowercase, 1 number, and 1 special character";
      isValid = false;
    }

    if (form.password !== form.c_password) {
      tempErrors["c_password"] = "Passwords do not match";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const formInputChangeHandler = (e) => {
    const { name, value, id } = e.target;

    if (name == 'userRole') {
      setUserTypeId(id);
    } else {
      setForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const submitClickHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (handleValidation()) {
      formData.append('email', form.email);
      formData.append('first_name', form.name);
      formData.append('phone_number', form.contactNumber);
      formData.append('role', userTypeId);
      formData.append('password', form.password);
      formData.append('agree_with_gnservices', gnService)
      formData.append('terms_and_conditions', gnTerm)
      formData.append('userrole', 'creator')

      infoSnack(formSnackbar.PRE_REGISTER_MSG)
      ApiService.post('register', formData).then((response) => {
        const updatedName = response?.data?.first_name || response?.data?.email;
        dispatch(setLoader(false));
        if (response.status === 200 || response.status === 201) {
          successSnack(response.data.message)
          setTimeout(() => history.push("/thank-you"), 1500);
        } else {
          errorSnack(updatedName)
        }
      })
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConPasswordVisibility = () => setShowConPassword(!showConPassword);

  return (
    <Box className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <Box className="row justify-content-center">
        <nav className="navbar navbar-light  border pb-0">
          <Box className="container-fluid">
            <a className="navbar-brand p-2" href="https://gntunes.com/">
              <img src={LogoApp} alt="" className="w-100" />
            </a>
          </Box>
        </nav>
        <Box className="login-form w-50 my-5 px-4 pb-4 border bg-white" >
          <form onSubmit={submitClickHandler}>
            <h5 className=" cardheading my-4">
              Get Started With Groovenexus
            </h5>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name *"
                  name="name"
                  value={form.name}
                  onChange={formInputChangeHandler}
                  error={!!errors.name}
                  helperText={errors.name}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email *"
                  name="email"
                  value={form.email}
                  onChange={formInputChangeHandler}
                  error={!!errors.email}
                  helperText={errors.email}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Phone No"
                  name="contactNumber"
                  value={form.contactNumber}
                  onChange={formInputChangeHandler}
                  error={!!errors.contactNumber}
                  helperText={errors.contactNumber}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Password *"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={form.password}
                  onChange={formInputChangeHandler}
                  error={!!errors.password}
                  helperText={errors.password || "Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character."}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Confirm Password *"
                  name="c_password"
                  type={showConPassword ? "text" : "password"}
                  value={form.c_password}
                  onChange={formInputChangeHandler}
                  error={!!errors.c_password}
                  helperText={errors.c_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleConPasswordVisibility}>
                          {showConPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <Box className="mb-2"><label>Your Primary Role *</label></Box>
                {userRole ? (
                  userRole.map((data, index) => (
                    <Box
                      key={index}
                      className="btn-group col-md-4 col-sm-12 col-12 col-lg-4 text-white p-0 pb-2"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="userRole"
                        id={data.value}
                        onChange={formInputChangeHandler}
                      />
                      <label
                        className={`${userTypeId == data.value ? 'backgroundColor text-white' : 'colorText '} btn borderColor rounded-0 overflow-hidden text-truncate`}
                        htmlFor={data.value}
                      >
                        <b>{data.label}</b>
                      </label>
                    </Box>
                  ))
                ) : (
                  <Skeleton height={60} animation="wave" />
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    sx={{
                      color: "red !im",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    className="form-check-input  checkbox"
                    id="exampleCheck1"
                    onClick={(e) => setGnTerm(prevvalue => !prevvalue)}
                  />
                  <label
                    className="form-check-label ps-2 mt-md-1 w-70"
                    htmlFor="exampleCheck1"
                  >
                    I have read the <span className="fw-bold text-decoration-underline colorText" >Terms and Conditions </span>
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    sx={{
                      color: "red !im",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    className="form-check-input  checkbox"
                    id="exampleCheck1"
                    onClick={(e) => setGnService(prevvalue => !prevvalue)}
                  />
                  <label
                    className="form-check-label ps-2 mt-md-1 w-70"
                    htmlFor="exampleCheck1"
                  >
                    Yes, I understand and agree to gntunes Services
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <span>
                  Already have account ?{" "}
                  <Link to="/" className="text-decoration-none colorText">
                    <strong>Login</strong>
                  </Link>
                </span>
                <button type="submit" className="gn-actionbtn float-end  mt-sm-3 mt-3 mt-lg-0 mt-md-0">Register</button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box >
      < SnackbarContainer />
      <Footer />
    </Box >
  );
}

export default Register;
