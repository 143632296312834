import logo from './logo.svg';
import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './admin/login';
import Dashboard from './module/dashboard/dashboard';
import Register from './admin/register';
import { Backdrop, } from '@mui/material';
import { CircularProgress } from '@mui/material';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Profile from './module/profile/profile';
import PrivateRoute from './PrivateRoute';
import { useSelector, useDispatch } from 'react-redux'
import { removeUserToken } from './_helpers/auth/secureToken'
import Forgot from './admin/forgot';
import ResetPassword from './admin/resetPassword';
import ChangePassword from './admin/changePassword';
import PageNotFound from './admin/PageNotFound';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SingupVerification from './admin/SingupVerification';
import SnackbarContainer from './module/Snackbar';
import { logoutHandler } from './_services/auth/logout.service';
import CampaignCard from './module/Campaign/CampaignCard'
import CampaignOverview from './module/Campaign/CampaignDetails';
function useQuery() {
  return new URLSearchParams(window.location.search);
}
const theme = createTheme({
  palette: {
    secondary: {
      main: '#333'
    }
  },
});
function App() {
  let query = useQuery();
  const [callbackStatus, setCallbackStatus] = useState(query.get("callback"));
  const dispatch = useDispatch();
  const openBackdrop = useSelector((state) => state.loader.loader);
  let authenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.href}`;

  function loginStatus() {
    let status = localStorage.getItem("auth");
    if (status) {
      return true;
    }
    else {
      return false;
    }
  }


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Switch>
          <PrivateRoute exact path="/profile" loginStatus={loginStatus}>
            <Profile />
          </PrivateRoute>
       
          ------------------**********************---------------
     
        -------------------*********************-------------------
       
        <PrivateRoute exact path="/dashboard" loginStatus={loginStatus}>
          <Dashboard />
        </PrivateRoute>

          <PrivateRoute exact path="/" loginStatus={loginStatus}>
            <Profile />
          </PrivateRoute>

          <PrivateRoute exact path="/campaign" loginStatus={loginStatus}>
            <CampaignCard />
          </PrivateRoute>
          <PrivateRoute exact path="/campaign-details" loginStatus={loginStatus}>
            <CampaignOverview />
          </PrivateRoute>
          
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/user_auth/activate/:tokenKey/:tokenValue">
            <Login />
          </Route>
          <Route exact path="/thank-you/">
            <SingupVerification />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          
          <Route exact path="/forgot-password">
            <Forgot />
          </Route>
         
          <Route exact path="/change-password">
            <ChangePassword />
          </Route>
          <Route exact path="/user_auth/passwordreset/:rowId/:token" >
            <ResetPassword />
          </Route>
          <Route path="/logout" render={() => {
            logoutHandler({})//called logout service
            removeUserToken();
            let callbackUrl = "/login";
            return <Redirect to={{ pathname: callbackUrl }} />;
          }
          }
          />
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
      <SnackbarContainer />
    </ThemeProvider>
  );
}

export default App;
