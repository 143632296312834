import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import ApiService from '../../_services/ApiService';

function Language(props) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [userLanguage, setUserLanguage] = useState()
    const [language, setLanguage] = useState([])
    const dispatch = useDispatch();

    useEffect(function () {
        setUserLanguage(userData?.data?.userprofile?.languages || [])
    }, [userData]);


    useEffect(function () {
        ApiService.get('getupdateLanguage').then(function (response) {
            dispatch(setLoader(false))
            setLanguage(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, []);

    function UpdateGenreCategory(postData) {
        dispatch(setLoader(true))
        infoSnack(formSnackbar.PRE_GENRE_UPDATE)
        ApiService.put('getupdateLanguage',postData, null, userData?.data?.id).then(function (response) {
            if (response.status === 200) {
                successSnack(formSnackbar.POST_GENRE_UPDATE)
                dispatch(setLoader(false))
            }
            else {
                dispatch(setLoader(false))
                errorSnack(formSnackbar.serviceRequest.serviceRequestFailure)
            }
        })
    }

    function onChangeGenreHandler(e, data) {
        var postData = new FormData()
        if (userLanguage.find(d => e.target.id == d)) {
            let currentSelected = userLanguage.filter(r => r != e.target.id);
            setUserLanguage([...currentSelected])
            let selectedIds = currentSelected ? currentSelected.length > 0 ? currentSelected.map(obj => obj) : [] : []
            postData.append('languages_ids', selectedIds.join())
            UpdateGenreCategory(postData)
        } else {
            setUserLanguage([...userLanguage, data.id])
            let selectedIds = [];
            selectedIds = userLanguage ? userLanguage.length > 0 ? userLanguage.map(obj => obj) : [] : []
            selectedIds = [...selectedIds, data.id]
            postData.append('languages_ids', selectedIds.join(','))
            UpdateGenreCategory(postData)
        }

    }
    return (
        <div>
            <div className='text-secondary'>
                <p>Choose the revelent language</p>
            </div>
            <div className='py-1 row px-4'>
                {language ? language.map(d => (
                    <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id} >
                        <div className="form-check-inline d-inline-flex">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => onChangeGenreHandler(e, d)}
                                        id={d.id.toString()}
                                        name={d.id.toString()}
                                        checked={userLanguage ? userLanguage.filter(v => v == d.id).length ? true : false : false}
                                    />}
                                    label={d.LanguageName} />
                            </FormGroup>
                        </div>
                    </div>
                )) : []}
            </div>
        </div>
    );
}

export default Language;