import React from 'react';
import { Card, Grid, Box, Button, Typography, IconButton } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useHistory } from 'react-router-dom';

const CampaignDetails = () => {
  const history = useHistory()

  const backroute = () => {
    history.push('/campaign')
  }

  return (
    <Box sx={{ padding: '0px 24px' }}>
      <IconButton className='py-4 gap-3' fontSize={'20px'} onClick={backroute} >
        <ArrowBackIcon />
        Kudi Mainu Kendi
      </IconButton>
      <Grid container spacing={2} >
        <Grid item xs={12} md={9}>

          <Card sx={{ padding: 3, borderRadius: 2 }}>
            <Grid container spacing={2}>
              {/* Left section for image */}
              <Grid item xs={12} md={4}>
                <Box component="img" src="image-url-here" alt="Kudi Mainu Kendi" sx={{ width: '100%', borderRadius: 1 }} />
              </Grid>

              {/* Right section for content */}
              <Grid item xs={12} md={8}>
                <Typography variant='subtitle1' className='colorBodyText' >Listen Now</Typography>
                {/* Button Row 1 - Listen Now */}
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Button variant="contained" className='backgroundColor' sx={{ mr: 1 }}>
                    GN Tunes
                  </Button>
                  <Button variant="contained" className='backgroundColor' sx={{ mr: 1 }}>
                    Spotify
                  </Button>
                  <Button variant="contained" className='backgroundColor' sx={{ mr: 1 }}>
                    Jio Saavn
                  </Button>
                  <Button variant="contained" className='backgroundColor'>
                    Instagram Reels Audio
                  </Button>
                </Box>

                {/* Button Row 2 - Watch Now / Download Clip */}
                <Box display={'flex'} sx={{ mb: 2 }}>
                  <Box >
                    <Typography variant='subtitle1' className='colorBodyText' >Watch Now</Typography>
                    <Button variant="contained" className='backgroundColor' startIcon={<YouTubeIcon />} sx={{ mr: 1 }}>
                      YouTube
                    </Button>
                  </Box>
                  <Box>
                    <Typography variant='subtitle1' className='colorBodyText' >Download Clip</Typography>
                    <Button variant="contained" className='backgroundColor' startIcon={<CloudDownloadIcon />}>
                      Download
                    </Button>
                  </Box>
                </Box>
              </Grid>

              {/* About Section */}
              <Grid item xs={12}>
                {/* Apply Now Button */}
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                  <Box>
                    <Typography variant="subtitle1" sx={{ color: '#FF4D49', fontWeight: 'bold', fontSize: '14px' }}>
                      Groovenexus
                    </Typography>
                    <Typography fontWeight="bold" className='colorBodyText' sx={{fontSize:'18px'}}>
                      Kudi Mainu Kendi
                    </Typography>
                  </Box>
                  <Button variant="contained" className='backgroundColor' sx={{ fontWeight: 'bold' }}>
                    <strong>APPLY NOW</strong>
                  </Button>
                </Box>
                <Typography  variant='subtitle1' className='colorBodyText'  sx={{ mt: 2, fontWeight: 'bold' }}>
                  About Song
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  This influencer marketing campaign aims to maximize the reach of "Kudi Mainu Kendi" and enhance GrooveNexus' brand presence.
                  Engaging influencers are encouraged to bring their creativity to the forefront while showcasing the song in a way that resonates with their audience.
                  The use of provided tags and the tagging of Rohan Shukla in the campaign is a must.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ padding: 3, borderRadius: 2 }}>
            <Grid container spacing={2} direction="column">
              {/* Campaign Price */}
              <Grid item container flexDirection={'column'} >
                <Box display={'flex'}>
                  <CurrencyRupeeIcon sx={{ marginRight: 1 }} />
                  <Typography variant="body1">Campaign Price</Typography>
                </Box>
                <Typography >INR 10/-</Typography>
              </Grid>

            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignDetails;
