import React, { useState, useEffect } from 'react';
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from "../../redux/loginUser/loginUserActions"
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { fetchUserDetail } from '../../redux';

function Phone(props) {
    const [phonerNumber, setphonerNumber] = useState("");
    const [errorMsg, setErrorMsg] = useState("")
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const userDetail = useSelector((state) => state.userDetail.userDetail);

    // useEffect(function () {
    //     dispatch(fetchLoginUser(userData.id))
    // }, [userData.id]);
    function onChangeNumberHandler(e) {
        e.preventDefault();
        var phoneno = /^\d{10}$/;
        if (e.target.value) {
            if (e.target.value.match(phoneno)) {
                setErrorMsg("")
                setphonerNumber(e.target.value)
            }
            else {
                setErrorMsg(<p className="text-danger">*Please Enter valid Number</p>)
            }
        } else {
            setErrorMsg(<p className="text-danger">*Please Enter Number</p>)

        }


    }

    function savePhoneNumber() {
        var postData = {}
        var phoneno = /^\d{10}$/;
        postData['contactNumber'] = phonerNumber
        if (phonerNumber.match(phoneno)) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage(formSnackbar.PRE_PHONE_UPDATE))
            dispatch(setSnackBarVariant('info'))
                updateProfile(postData, userData.id).then(function (response) {
                if(response.status === 200){
                    dispatch(setSnackBarMessage(formSnackbar.POST_PHONE_UPDATE))
                    dispatch(setSnackBarVariant('success'))
                    setTimeout(() =>  dispatch(setSnackBar(false)), 3000);
                    dispatch(fetchUserDetail()) //to get the updated data of user
                }
                else{
                    dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                    dispatch(setSnackBarVariant('error'))
                    setTimeout(() => dispatch(setSnackBar(false)), 3000);
                }
            })
        }
    }

    return (
        <div className="row p-3">
            <h4 className='mb-4'>Phone</h4>
            <div className="card account-bg">
                <div className="card-body">
                    <p>Add your Phone Number</p>
                    <div className='h3 text-center w-100 d-flex'>
                        {/* <select class="form-control bfh-countries" data-country="US"></select> */}
                        <div className='bg-white border ps-2 pe-2'>
                        <img src='/countryFlag.svg' />

                        </div>

                        <input type="text" className='border fs-6 pb-2 ps-3 pt-2 rounded w-100'
                            placeholder='Enter phone number'
                            onChange={onChangeNumberHandler}
                            defaultValue={userData ? userData.contactNumber : phonerNumber} />
                        <button className='bg-white border fs-6 fw-bold rounded' onClick={savePhoneNumber}>Save</button>
                    </div>

                    {errorMsg}
                </div>
            </div>
        </div>
    );
}

export default Phone;