import React, { useState } from "react";
import { Box, Drawer as MuiDrawer, AppBar as MuiAppBar, Toolbar, List, CssBaseline, IconButton, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { NavLink } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import User from '../user';
import LogoutIcon from '@mui/icons-material/Logout';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoApp from '../../img/Gn-Creator-logo.svg'
import { useSelector } from "react-redux";
import CampaignIcon from '@mui/icons-material/Campaign';
const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  zIndex:'9',
  [theme.breakpoints.down('md')]: {
    width: '75px !important',
		},
  width: '95px !important',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const StyledList = styled(List)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'red',
    '&, & .MuiListItemIcon-root': {
      color: 'pink',
    },
  },
  // hover states
  '& .MuiSvgIcon-root': { color: '#FFFFFF' },
  '& .active .MuiListItemButton-root': {
    backgroundColor: '#fff',
    '& .MuiSvgIcon-root, &, & .MuiListItemIcon-root': { color: 'red' },
  },
  '& .MuiListItemButton-root': { color: '#fff' },
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#FFF',
    '&, & .MuiListItemIcon-root': {
      color: 'red',
    },
    '& .MuiSvgIcon-root': { color: 'red' },
  },
});
const hwset = { height: '18px', width: '18px' }

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);
  const [openRoyalty, setOpenRoyalty] = useState(false);
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)
  const userData = useSelector((state) => state.userData.userData);

  const navItems = [
    { id: 1, to: "/dashboard", text: "Dashboard", icon: <DashboardIcon sx={hwset} />, },
    { id: 1, to: "/campaign", text: "Campaign", icon: <CampaignIcon sx={hwset} />, },
    { id: 12, to: "/logout", text: "Logout", icon: <LogoutIcon sx={hwset} style={{ transform: 'rotate(180deg)' }} /> }
  ];


  const handleDrawerOpen = () => {
    handleClose()
    setOpen(!open);
  };

  function handleClickMenu(event, data) {
    setAnchorEl(event.currentTarget);
    setMenuRowId(data.id);
    if (data.text == "Song Hub") {
      handleClick()
    }
  }

  const handleClick = () => {
    setOpenRoyalty(!openRoyalty);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };


  const listRender = (item) => {
    return (
      <ListItemButton className={`${open ? '' : 'pb-0'} rounded-3 `} onClick={(e) => handleClickMenu(e, item)} sx={{
        minHeight: 48,
        marginTop: '14px !important',
        justifyContent: open ? 'initial' : 'center',
        flexDirection: open ? '' : 'column',
        alignItems: open ? '' : 'center',
        px: 2.5,
      }}>
        <ListItemIcon sx={{
          minWidth: 0,
          mr: open ? 3 : '0px',
          justifyContent: 'center',
        }}>
          {item.icon}
          {(item.hasCollapse && !open) ? <ChevronRightIcon sx={{
            position: 'absolute',
            right: '3px',
            top: '7px',
            width: '18px',
            height: '18px'
          }} /> : null}
        </ListItemIcon>
        <ListItemText primary={<Typography variant="span" sx={!open && {
          fontSize: '11px', display: item.text.length > 10 ? 'block' : 'inline-block',
          whiteSpace: item.text.length > 10 ? 'break-spaces' : 'nowrap',
          textAlign: 'center',
        }}>{item.text}</Typography>} />
        {(item.hasCollapse && open) ? (openRoyalty ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItemButton >
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className='bg-white shadow-none border' position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className="text-black ms-1"
            edge="start"

          >
            <MenuOpenIcon />
          </IconButton>
          <Box sx={{
            marginLeft: 4,
           
          }}>
            <img src={LogoApp} className="w-100" />
          </Box>
          <Box className='d-flex align-items-center gap-5 w-100 justify-content-end' >
            <User />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            marginTop: '4rem',
            padding: "10px",
            boxSizing: "border-box",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            background: 'linear-gradient(180deg, #ED1C24 0%, #AA0066 100%)',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.55)',
          },
        }}
        variant="permanent" open={open}>
        <List sx={{ width: "100%", maxWidth: 360, paddingBottom: '5rem !important' }} className="p-0" component="nav" aria-labelledby="nested-list-subheader">
          <StyledList sx={{ padding: '0px' }}>
            {navItems.map((item, index) => (
              <React.Fragment key={index}>
                {item.hasCollapse ? (
                  <div>
                    {listRender(item)}
                    <Menu
                      id={`basic-menu-${item.id}`}
                      anchorEl={anchorEl}
                      open={!open && menuRowId === item.id}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      MenuListProps={{
                        'aria-labelledby': `basic-button-${item.id}`,
                      }}
                    >
                      {item.collapseItems.map((option, i) => (
                        <MenuItem
                          key={i}
                          to={option.to}
                          className="p-0"
                        >
                          <NavLink onClick={handleClose} to={option.to} className="nav-list-items py-1 ">
                            {/* <ListItemText primary={option.text} /> */}
                            <ListItemButton className="tab-items rounded-3 ps-0" >
                              <ListItemIcon className="justify-content-center align-items-center" >
                                {option.icon}
                              </ListItemIcon>
                              <ListItemText primary={<Typography variant="span">{option.text}</Typography>} />
                            </ListItemButton>
                          </NavLink>
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>)
                  :
                  (<NavLink to={item.to} className='nav-list-items '>
                    {listRender(item)}
                  </NavLink>)
                }
                {(item.hasCollapse && open) && (
                  <Collapse in={openRoyalty} timeout={300} unmountOnExit>
                    {item.collapseItems.map((collapseItem, collapseIndex) => (
                      <NavLink key={collapseIndex} to={collapseItem.to} className="nav-list-items">
                        <ListItemButton onClick={collapseItem.handleClick} className="tab-items my-2 rounded-3" >
                          <ListItemIcon className="p-2 justify-content-center align-items-center" >
                            {collapseItem.icon}
                          </ListItemIcon>
                          <ListItemText primary={collapseItem.text} />
                        </ListItemButton>
                      </NavLink>
                    ))}
                  </Collapse>
                )}

              </React.Fragment>
            ))}
          </StyledList>
        </List>
      </Drawer>
    </Box>
  );
}
