import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserDetail } from '../redux/user/userDataActions';
import profileImge from '../img/profileImage.png'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockIcon from '@mui/icons-material/Lock';
import { Typography, styled } from '@mui/material';

function User(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(function () {
    dispatch(fetchUserDetail());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, px: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableRipple
          >
            <Avatar src={userData?.data?.userprofile?.avatar || profileImge} onError={(e) => { e.target.src = profileImge }}>
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '200px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography variant="h6" className='text-decoration-none text-black mx-3 text-capitalize' sx={{ fontSize: '1rem', padding: '0.5rem', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600 }}>{userData?.data?.username || ''}</Typography>
        <div className="class-name"></div>
        <Link to="/profile" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <div >Account</div>
          </MenuItem>
        </Link>
        <Link to="/change-password" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            <div >Change Password</div>
          </MenuItem>
        </Link>
        <div className="class-name"></div>
        <Link to="/logout" className='text-decoration-none text-black '>
          <div className='p-2 mx-3 pt-3'> <strong>LOG OUT</strong></div>
        </Link>
      </Menu>

    </div>

  );
}
export default User;