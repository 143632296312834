import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Button, Chip, List, ListItem, Divider, Avatar } from '@mui/material';
import { MusicNote, CheckCircle, Star } from '@mui/icons-material'; // Icons for Chips

const CampaignOverview = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        
        {/* Left Side Section */}
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={12} md={4}>
                  {/* Image */}
                  <Avatar variant="square" src="/path/to/image" sx={{ width: '100%', height: 140 }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  {/* Title, Subtitle and Tags */}
                  <Typography variant="h6">Campaign Overview</Typography>
                  <Typography variant="body2" color="text.secondary">Kudi Chamke - Kawachi</Typography>

                  {/* Tags (Chips) */}
                  <Box sx={{ mt: 2 }}>
                    <Chip label="Pop" icon={<MusicNote />} sx={{ marginRight: 1 }} />
                    <Chip label="Song" icon={<CheckCircle />} sx={{ marginRight: 1 }} />
                    <Chip label="Featured" icon={<Star />} />
                  </Box>

                  {/* Apply Button */}
                  <Box sx={{ mt: 2 }}>
                    <Button variant="contained" color="primary">Apply Now</Button>
                  </Box>
                </Grid>
              </Grid>

              {/* Description Section */}
              <Divider sx={{ my: 2 }} />
              <Typography variant="body1">
                A brief description about the song and campaign goals here. Explain deliverables, timeline, etc.
              </Typography>

              {/* Deliverables */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Deliverables</Typography>
                <List>
                  <ListItem>Deliverable 1</ListItem>
                  <ListItem>Deliverable 2</ListItem>
                  <ListItem>Deliverable 3</ListItem>
                </List>
              </Box>

              {/* Criteria */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Application Criteria</Typography>
                <List>
                  <ListItem>Criteria 1</ListItem>
                  <ListItem>Criteria 2</ListItem>
                  <ListItem>Criteria 3</ListItem>
                </List>
              </Box>

              {/* Divider */}
              <Divider sx={{ my: 2 }} />
              {/* Additional Opportunities */}
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Additional Opportunities</Typography>
                <List>
                  <ListItem>Opportunity 1</ListItem>
                  <ListItem>Opportunity 2</ListItem>
                </List>
              </Box>

            </CardContent>
          </Card>
        </Grid>

        {/* Right Side Section */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Campaign Stats</Typography>
              <List>
                <ListItem>Stat 1</ListItem>
                <ListItem>Stat 2</ListItem>
                <ListItem>Stat 3</ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignOverview;
