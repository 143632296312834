import React, { useEffect, useState } from 'react';
import { Box, Button, Card, IconButton, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from '../../_services/ApiService';
import { useSelector } from 'react-redux';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

function BankDetail({ bankDetails, setBankDetails, bankUpiDetails }) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const [errors, setErrors] = useState([{}]);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedDetails = [...bankDetails];
        updatedDetails[index][name] = value;
        setBankDetails(updatedDetails);
    };

    const validateFields = (index) => {
        let isValid = true;
        // const newErrors = bankDetails.map(detail => {
        const fieldErrors = {};
        const detail = bankDetails[index];
        ['bank_name', 'account_number', 'ifsc_code', 'account_holder_name'].forEach(field => {
            if (!detail[field]) {
                fieldErrors[field] = `${field.replace('_', ' ')} is required`;
                isValid = false;
            }
        });

        if (detail.bank_name && !/^[a-zA-Z\s]+$/.test(detail.bank_name) || detail.bank_name.length < 4) {
            fieldErrors.bank_name = "Bank name should only contain alphabets and spaces, and be at least 4 characters long";
            isValid = false;
        }

        if (detail.account_number && !/^\d{9,18}$/.test(detail.account_number)) {
            fieldErrors.account_number = "Account number should be between 9 to 18 digits";
            isValid = false;
        }

        if (detail.ifsc_code && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(detail.ifsc_code)) {
            fieldErrors.ifsc_code = "Invalid IFSC code format";
            isValid = false;
        }

        if (detail.account_holder_name && (!/^[a-zA-Z\s]+$/.test(detail.account_holder_name) || detail.account_holder_name.length < 4)) {
            fieldErrors.account_holder_name = "Account holder name should contain only alphabets and spaces, and be at least 4 characters long";
            isValid = false;
        }

        const updatedErrors = [...errors];
        updatedErrors[index] = fieldErrors;
        setErrors(updatedErrors);

        return isValid;
    };


    const addNewBankHandler = (e, index) => {
        e.preventDefault()
        if (validateFields(index)) {
            let formData = new FormData()
            const { bank_name, account_number, ifsc_code, account_holder_name } = bankDetails[index];
            const cleanedBankDetails = {
                bank_name,
                account_number,
                ifsc_code,
                account_holder_name
            };
            Object.entries(cleanedBankDetails).forEach(([key, value]) => {
                formData.append(key, value)
                console.log("bankDetails: bankDetails", key, value)
            });
            formData.append('payment_method', 'BANK')
            if (bankDetails[index]?.id) {
                infoSnack('Bank Account Updating...')
                ApiService.put('getpostBankDetails', formData, null, bankDetails[index]?.id).then(res => {
                    if (res.status == 200) {
                        successSnack('Bank Details Updated Successfully.')
                        bankUpiDetails()
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            else {
                infoSnack('Bank Account Details Adding...')
                ApiService.post('getpostBankDetails', formData).then(res => {
                    if (res.status == 200) {
                        successSnack('Bank Details Added Successfully.')
                        bankUpiDetails()
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    };

    const addMoreBank = () => setBankDetails([...bankDetails, { bank_name: '', account_number: '', ifsc_code: '', account_holder_name: '' }])

    const removeBankDetail = (data, index) => {
        if (data?.id) {
            ApiService.delete('deleteBankDetails', {}, data?.id).then(res => {
                if (res.status == 200) {
                    successSnack(res?.data?.message)
                    setBankDetails(bankDetails.filter((_, i) => i !== index))
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            setBankDetails(bankDetails.filter((_, i) => i !== index))
        }
    };

    return (
        <Box sx={{ p: 1 }}>
            <Card sx={{ p: 2, backgroundColor: '#f9f7f7' }}>
                <Typography variant="subtitle1">Bank Details</Typography>

                {bankDetails.map((detail, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>Account {index + 1}</Typography>
                            <IconButton onClick={() => removeBankDetail(detail, index)}><DeleteIcon /></IconButton>
                        </Box>
                        {['bank_name', 'account_number', 'ifsc_code', 'account_holder_name'].map((field) => (
                            <TextField
                                key={field}
                                name={field}
                                label={field.replace('_', ' ').toUpperCase()}
                                value={detail[field]}
                                onChange={(e) => handleChange(index, e)}
                                error={!!errors[index]?.[field]}
                                helperText={errors[index]?.[field]}
                                required
                                fullWidth
                                margin="dense"
                            />
                        ))}
                        <Box className='text-end w-100'>
                            <Button onClick={(e) => addNewBankHandler(e, index)} variant="text" className='colorText' >Save Bank Detail</Button>
                        </Box>
                    </Box>
                ))}
                <Button disabled={bankDetails.length == 3} onClick={addMoreBank} variant="contained" className='backgroundColor borderColor'>+ ADD MORE</Button>
            </Card>
        </Box>
    );
}

export default BankDetail;
