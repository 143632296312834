import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import ApiService from '../../_services/ApiService';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

function Category(props) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [categories, setCategories] = useState([])
    const [userCatogory, setUserCatogory] = useState([])
    const dispatch = useDispatch();

    useEffect(function () {
        setUserCatogory(userData?.data?.userprofile?.category || [])
    }, [userData]);


    useEffect(function () {
        ApiService.get('getupdateCategory').then(function (response) {
            dispatch(setLoader(false))
            setCategories(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })

    }, []);

    function UpdateUserCategory(postData) {
        dispatch(setLoader(true))
        infoSnack(formSnackbar.PRE_CATEGORY_UPDATE)
        ApiService.put('getupdateCategory',postData,null, userData?.data?.id).then(function (response) {
            dispatch(setLoader(false))
            if (response.status === 200) {
                successSnack(formSnackbar.POST_CATEGORY_UPDATE)
            }
            else {
                errorSnack(formSnackbar.serviceRequest.serviceRequestFailure)
            }
        })
    }

    function onChangeCategoryHandler(e, data) {
        var postData = new FormData()
        if (userCatogory.find(d => e.target.id == d)) {
            let currentSelected = userCatogory.filter(r => r != e.target.id);
            setUserCatogory([...currentSelected])
            let selectedIds = currentSelected ? currentSelected.length > 0 ? currentSelected.map(obj => obj) : [] : []
            postData.append('category', selectedIds.join())
            UpdateUserCategory(postData)
        } else {
            setUserCatogory([...userCatogory, data.id])
            let selectedIds = [];
            selectedIds = userCatogory ? userCatogory.length > 0 ? userCatogory.map(obj => obj) : [] : []
            selectedIds = [...selectedIds, data.id]
            postData.append('category_ids', selectedIds.join(','))
            UpdateUserCategory(postData)
        }
    }
    return (
        <div className="py-2">
            <div className='text-secondary'>
                <p>What best describes you? categories help people find accounts like yours.</p>
            </div>

            <div className='py-1 row px-4  '>
                {categories ? categories.map(d => (
                    <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id}>
                        <div className="form-check-inline d-inline-flex">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => onChangeCategoryHandler(e, d)}
                                        id={d.id.toString()}
                                        name={d.id.toString()}
                                        checked={userCatogory ? userCatogory.filter(v => v == d.id).length ? true : false : false}
                                    />}
                                    label={d.category} />

                            </FormGroup>
                        </div>
                    </div>
                )) : null}
            </div>
            {/* </div> */}
            {/* </div> */}
        </div>
    );
}

export default Category;