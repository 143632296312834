import React, { useState } from 'react';
import { Email } from '@mui/icons-material'
import { Lock } from '@mui/icons-material'
import { useHistory, useLocation, Redirect, useParams } from 'react-router-dom';
import logo from '../img/Gn-Creator-logo.svg'
import Footer from './footer';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import { changePassword } from '../_services/changePassword/changePassword.service';
import { setLoader } from '../redux';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux'
import backgroundImage from '../img/bg_image_texture3.png'
import { Box, Grid } from '@mui/material';
import SnackbarContainer from '../module/Snackbar';
import useSnackDispatcher from '../_helpers/reusablefunctions/snackBarComponent';

function ChangePassword(props) {
    const { successSnack, errorSnack } = useSnackDispatcher();
    const dispatch = useDispatch();
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassError, setNewPassError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var regexp = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (password.match(regexp) || format.test(password)) {
            data.append('old_password', password);
            setPassError("")
        } else {
            setPassError(
                <span className="text-danger mt-1">Please enter valid password </span>
            )
        }
        if (newPassword.match(regexp) || format.test(newPassword)) {
            data.append('new_password', newPassword);

            setNewPassError("")
        } else {
            setNewPassError(
                <span className="text-danger mt-1">Please enter valid password </span>
            )
        }
        if (password.match(regexp) || format.test(password) && newPassword.match(regexp) || format.test(newPassword)) {
            dispatch(setLoader(true))
            changePassword(data).then(function (response) {
                successSnack('Password updated successfully.')
                dispatch(setLoader(false))
            }).catch(function (error) {
                dispatch(setLoader(false))
                errorSnack(error.response)
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        }
    }

    return (
        <Box className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="https://gncreators.com/"><img src={logo} className='w-100' /></a>
                    </div>
                </nav>
                <Box className="login-form w-50 my-5 px-4 pb-4 border bg-white m-auto" >
                    <form action="" method="" >
                        <h5 className="cardheading text-center my-3">Change Your Password</h5>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <div className="border  d-flex p-2 rounded">
                                    <InputBase
                                        type={showPassword ? "text" : "password"}
                                        className="ms-2 w-100"
                                        placeholder="Old Password *"
                                        name="password"
                                        onChange={e => { setPassword(e.target.value); setPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {passError}
                            </Grid>

                            <Grid item xs={12}>
                                <div className="border  d-flex p-2 rounded">
                                    <InputBase
                                        type={showNewPassword ? "text" : "password"}
                                        className="ms-2 w-100"
                                        placeholder=" New Password *"
                                        name="new password"
                                        onChange={e => { setNewPassword(e.target.value); setNewPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowNewPassword(!showNewPassword)}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {newPassError}
                            </Grid>

                            <Grid item xs={12}>
                                <span className='fs-08'>
                                    Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character.
                                </span>
                            </Grid>
                            <Grid item xs={12}>
                                <span>
                                    Go to{": "}
                                    <Link to="/" className="text-decoration-none colorText">
                                        <strong>Login</strong>
                                    </Link>
                                </span>
                                <button type="submit" className="gn-actionbtn float-end  mt-sm-3 mt-3 mt-lg-0 mt-md-0" onClick={onClickHandler}>Submit</button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </div >
            <Footer />
            <SnackbarContainer />
        </Box >
    );
}



export default ChangePassword;