import { combineReducers } from 'redux'
import loader from './loader/loaderReducer'
import userData from './user/userDataReducer'
import userDetail from './loginUser/loginUserReducer'
import snackBar from './snackBar/snackBarReducer'
import { USER_LOGOUT } from './logout/logoutTypes'

const appReducer  = combineReducers({
    loader:loader,
    userData:userData,
    userDetail:userDetail,
    snackBar:snackBar,
})

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
      // Reset the state to its initial values on user logout
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer
