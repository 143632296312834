import React, { useState } from 'react';
import Footer from './footer';
import { forgotPassword } from '../_services/forgotPassword';
import Box from "@mui/material/Box";
import { useDispatch } from 'react-redux'
import { setLoader } from '../redux';
import Modal from "@mui/material/Modal";
import { TextField } from '@mui/material';
import backgroundImage from '../img/bg_image_texture3.png'
import { Link } from "react-router-dom";
import LogoApp from '../img/Gn-Creator-logo.svg'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 3
};
function Forgot(props) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
            data.append('email', email);
            setEmailError("")
        } else {
            setEmailError(
                <label className="text-danger mt-1" style={{ marginLeft: '10px' }}>Please enter valid Email</label>
            )
        }
        if (email.match(mailformat)) {
            dispatch(setLoader(true))
            forgotPassword(data).then(function (response) {
                handleOpen()
                dispatch(setLoader(false))
            }).catch(function (error) {
                dispatch(setLoader(false))
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        }
    }

    return (
        <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="https://gntunes.com/"><img src={LogoApp} className='w-100' /></a>
                    </div>
                </nav>
                <div style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
                    <div className="login-form  mt-4 mb-4 p-4 border rounded bg-white">
                        <form action="" method="" className="row g-3">
                            <h6 className="text-secondary mx-2 ">
                                Enter your registered email address, mobile number, or username to change your Groovenexus account password.
                            </h6>
                            <div >
                                <TextField
                                    id="outlined-basic"
                                    label="Email"
                                    variant="outlined"
                                    size='small'
                                    className="w-100"
                                    placeholder="Email"
                                    onChange={e => { setEmail(e.target.value); setEmailError("") }}
                                />
                            </div>
                            {emailError}
                            <div>
                            </div>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style} className='text-secondary' >
                                    {/* <h6>Mail has been sent to your registered email</h6> */}
                                    <h6>Password reset link has been send to your registered email address.</h6>

                                </Box>
                            </Modal>
                            <div className="col-md-12">
                                <Link to="/" className="text-decoration-none colorText">
                                    <strong>Back to Login page</strong>
                                </Link>
                                <button type="submit" className="gn-actionbtn float-end w-40 py-2" onClick={onClickHandler}>SEND PASSWORD RESET LINK</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Forgot;