import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import ApiService from '../../_services/ApiService';
import { TextField } from '@mui/material';

function Language(props) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [userLanguage, setUserLanguage] = useState()
    const [language, setLanguage] = useState([])
    const [languageChecked, setLanguageChecked] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch();

    useEffect(function () {
        setUserLanguage(userData?.data?.userprofile?.languages || [])
    }, [userData]);

    function getLanguageList() {
        ApiService.get('getupdateCreate',{},'language/').then(function (response) {
            dispatch(setLoader(false))
            setLanguage(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })
    }
    useEffect(function () {
        getLanguageList()

    }, []);

    function UpdateGenreCategory(postData) {
        dispatch(setLoader(true))
        infoSnack(formSnackbar.PRE_GENRE_UPDATE)
        ApiService.put('getupdateCreate', postData, null, userData?.data?.id).then(function (response) {
            if (response.status === 200) {
                successSnack(formSnackbar.POST_GENRE_UPDATE)
                dispatch(setLoader(false))
            }
            else {
                dispatch(setLoader(false))
                errorSnack(formSnackbar.serviceRequest.serviceRequestFailure)
            }
        })
    }

    function onChangeGenreHandler(e, data) {
        var postData = new FormData()
        if (userLanguage.find(d => e.target.id == d)) {
            let currentSelected = userLanguage.filter(r => r != e.target.id);
            setUserLanguage([...currentSelected])
            let selectedIds = currentSelected ? currentSelected.length > 0 ? currentSelected.map(obj => obj) : [] : []
            postData.append('languages_ids', selectedIds.join())
            UpdateGenreCategory(postData)
        } else {
            setUserLanguage([...userLanguage, data.id])
            let selectedIds = [];
            selectedIds = userLanguage ? userLanguage.length > 0 ? userLanguage.map(obj => obj) : [] : []
            selectedIds = [...selectedIds, data.id]
            postData.append('languages_ids', selectedIds.join(','))
            UpdateGenreCategory(postData)
        }
    }

    function CreateUserLanguage(postData) {
        infoSnack("Language is creating..")
        ApiService.post('getupdateCreate', postData, 'language').then(function (response) {
            if (response.status === 200) {
                setLanguageChecked(false)
                getLanguageList()
                successSnack('Language created successfully.')
            }
            else {
                errorSnack(response?.data?.LanguageName[0])
            }
        })
    }

    function onChangeLanguage(e) {
        const { value } = e.target;
        var postData = new FormData()
        if (e.type == 'blur') {
            if (value.length > 3) {
                setError(false)
                postData.append('LanguageName', value)
                CreateUserLanguage(postData)
            } else {
                setError(true)
            }
        }
    }

    return (
        <div>
            <div className='text-secondary'>
                <p>Choose the revelent language</p>
            </div>
            <div className='py-1 row px-4'>
                {language ? language.map(d => (
                    <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id} >
                        <div className="form-check-inline d-inline-flex">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => onChangeGenreHandler(e, d)}
                                        id={d.id.toString()}
                                        name={d.id.toString()}
                                        checked={userLanguage ? userLanguage.filter(v => v == d.id).length ? true : false : false}
                                    />}
                                    label={d.LanguageName} />
                            </FormGroup>
                        </div>
                    </div>
                )) : []}
                <div className="col-md-4 col-sm-12 col-lg-3 p-0 ">
                    <div className="form-check-inline d-inline-flex">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={(e) => setLanguageChecked(e.target.checked)}
                                    id='other-Language'
                                    name='otherLanguage'
                                    checked={languageChecked}
                                />}
                                label='Other' />

                        </FormGroup>
                    </div>
                </div>
                {languageChecked && <div className='p-0 col-md-12 col-sm-12 col-lg-12 mt-3'>
                    <TextField
                        label="Language"
                        id="outlined-size-small"
                        placeholder='Please enter Language'
                        onChange={onChangeLanguage}
                        onBlur={onChangeLanguage}
                        size="small"
                        sx={{ width: '100%' }}
                        name="language"
                        color="secondary"
                        type="text"
                        error={error}
                        helperText={error && "Enter at least 4 characters."}
                    />
                </div>}
            </div>
        </div>
    );
}

export default Language;