import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Redirect, useParams } from 'react-router-dom';
import backgroundImage from '../img/bg_image_texture3.png'
import Footer from "./footer";
import { Link } from "react-router-dom";
import { login } from "../_services/auth/auth.service";
import { setSelfToken, setUserId } from "../_helpers/auth/secureToken";
import { setLoader } from "../redux";
import { useDispatch } from "react-redux";
import { isLogin } from "../_helpers/auth/secureToken";
import { activateUserEmail } from "../_services/register/ActivateUserEmail";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton, InputAdornment, InputBase, TextField } from "@mui/material";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { formSnackbar } from "../_services/_constants/snackbar.constant";
import useSnackDispatcher from "../_helpers/reusablefunctions/snackBarComponent";
import LogoApp from '../img/Gn-Creator-logo.svg'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 3,
  boxShadow: 24,
  p: 3
};

function Login(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
  let { tokenKey, tokenValue } = useParams();
  let { from } = location.state || { from: { pathname: "/" } };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [passError, setPassError] = useState("");
  const [emailError, setEmailError] = useState();
  const [open, setOpen] = React.useState(false); //to show and hide the modal
  const handleClose = () => setOpen(false)
  const [modalText, setModalText] = useState('')
  useEffect(() => {
    if (tokenKey && tokenValue) {
      dispatch(setLoader(true))
      let fullURL = tokenKey + '/' + tokenValue + '/'
      activateUserEmail(fullURL)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false))
            setOpen(true)
            setModalText(response.data.data[0].message)
            setTimeout(() => setOpen(false), 2000)
            //set login path
            setSelfToken(response.data.data[0].token);
            setUserId(response.data.data[0].users.id)
            history.replace(from.pathname);
          }
          else {
            dispatch(setLoader(false))
            setOpen(true)
            setModalText('Some error occured while activating your account. Please try again later')
          }
        }).catch(function (error) {
          dispatch(setLoader(true))
          return error.response;
        })
    }
  }, [])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPassError("")
  };

  async function loginClickHandler(e) {
    e.preventDefault();
    const formData = new FormData();

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var regexp = " /^[0-9\b]+$/";
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (email.match(mailformat)) {
      setEmailError("");
      formData.append('email', email);
    } else {
      setEmailError(
        <label className="m-0 colorText small ">{formSnackbar.errors.validEmail}</label>
      );
    }
    if (password.match(regexp) || format.test(password)) {
      setPassError("");
      formData.append('password', password);
    } else {
      setPassError(
        <span className="m-0 colorText small ">{formSnackbar.errors.validPassword}</span>
      );
    }
    if (
      (email.match(mailformat) && password.match(regexp)) ||
      format.test(password)
    ) {
      dispatch(setLoader(true));
      const response = await login(formData);
      console.log("login success", response);
      if (response.status === 200) {
        dispatch(setLoader(false));
        var output = response.data;
        if (output) {
          setSelfToken(output.token);
          history.replace(from.pathname);
          dispatch(setLoader(false));
        } else {
          console.log(response);
        }
      }else{
        dispatch(setLoader(false));
        errorSnack(response?.data?.message);
        return response.message;
      } 
    }
  }

  if (isLogin()) {

    return <Redirect to={from.pathname} />;
  } else {
    return (
      <div className="container-fluid  " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="row">
          <nav className="navbar navbar-light  border pb-0">
            <div className="container-fluid">
              <a className="navbar-brand" href="https://gntunes.com/">
                <img src={LogoApp} alt="logo" className="w-100" />
              </a>
            </div>
          </nav>
          <div style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
            <div className="mt-4 mb-4 p-4 border bg-white">
              <h4 className="cardheading">Login </h4>
              <form action="" method="" className="row g-3">
                <div className="text-secondary mt-4">
                  Don't have an account   <Link to="/register" className="text-decoration-none colorText">
                    <strong>Register</strong> {" "}
                  </Link>
                </div>
                <div >
                  <TextField
                    id="outlined-basic"
                    label="Email*"
                    variant="outlined"
                    size="small"
                    className="w-100"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                  />
                </div>
                {emailError}
                <div>
                  <TextField
                    label="Password*"
                    size="small"
                    className="w-100"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={password}
                    onChange={handlePasswordChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                    helperText='Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character.'
                  />
                </div>
                {passError}
                {errorMsg}
                <div className="col-md-12 pb-4">
                  <a href="/forgot-password" className="colorText">
                    {" "}
                    <strong>Forgot Password?</strong>
                  </a>
                </div>
                <div>
                  <button
                    type="submit"
                    className="gn-actionbtn float-end py-2 w-100"
                    onClick={loginClickHandler}
                  >
                    SIGN IN
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h6>{modalText}.</h6>
          </Box>
        </Modal>
      </div>
    );
  }
}

export default Login;
