import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import profileImge from '../../img/profileImage.png'
import { updateProfile } from '../../_services/profile/profileUpdate.service';
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { setLoader } from '../../redux';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Fade from '@mui/material/Fade';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import '../../admin/style.css'
import Typography from '@mui/material/Typography';
import AvatarEditor from 'react-avatar-editor';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Slider from '@mui/material/Slider';
import CropIcon from '@mui/icons-material/Crop';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import useMediaQuery from '@mui/material/useMediaQuery';
import ApiService from '../../_services/ApiService';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';

function General(props) {
    const smScreen = useMediaQuery('(max-width:600px)');
    const xsSmScreen = useMediaQuery('(max-width:375px)');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: smScreen ? '100%' : 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 3,
    };
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch();
    const [saveBtn, setSaveBtn] = useState(true)
    const [userName, setUserName] = useState("")
    const [errorName, setErrorName] = useState(false);
    const [helperTextName, setHelperTextName] = useState("")

    const [primaryNo, setPrimaryNo] = useState("")

    const [whatsappNo, setwhatsappNo] = useState("")
    const [whatsappNoError, setwhatsappNoError] = useState(false)

    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)

    const [location, setLocation] = useState("")

    const [agencyId, setAgencyId] = useState("")
    const [agencyIdError, setAgencyIdError] = useState(false)

    const [userThumbnai, setUserThumbnai] = useState("");
    const [avatarFile, setAvatarFile] = useState()

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const editorRef = React.useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [scale, setScale] = useState(1);
    const mobileNumberRegex = /^[0-9]{10}$/;
    const allowedExtensions = ['.png', '.jpg', '.jpeg'];

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        const fileExtension = '.' + file.name.split('.').pop();
        if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage('Invalid file format. Only PNG/JPG/JPEG files are allowed.'))
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return;
        }

        const maxSize = 10 * 1024 * 1024; // 10MB
        if (file.size > maxSize) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage('File size exceeds the maximum limit (10MB).'))
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            return;
        }

        // Validation: Allowed resolution
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            handleOpen();
            setAvatarFile(file)
            // setSelectedFile(file);
            setPreviewImage(URL.createObjectURL(file));
            e.target.value = null;
        };
    };

    const handleCrop = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            const croppedImageUrl = canvas.toDataURL();
            // setCroppedImage(croppedImageUrl);
            setUserThumbnai(croppedImageUrl)
            // canvas.toBlob((blob) => {
            //     setAvatarFile(blob)
            // }, 'image/jpeg');
        }
        handleClose() //close the modal
    };

    const handleScaleChange = (e) => {
        const scaleValue = parseFloat(e.target.value);
        setScale(scaleValue);
    };

    useEffect(function () {
        if (userData?.data?.id) {
            setUserThumbnai(userData?.data?.userprofile?.avatar || '')
            setUserName(userData?.data?.first_name || '')
            setEmail(userData?.data?.email || '')
            setAgencyId(userData?.data?.userprofile.agency_id || '')
            setLocation(userData?.data?.userprofile.location || '')
            setwhatsappNo(userData?.data?.userprofile.whatsapp_no || '')
            setPrimaryNo(userData?.data?.userprofile.phone_number || '')

        }
    }, [userData]);

    const validateForm = () => {
        if (userName === '' || userName === null) {
            setErrorName(true)
            return false
        }
        else
            return true
    }

    const phoneChangeHandler = (e) => {
        const { value } = e.target;
        const isValid = mobileNumberRegex.test(value);
        setwhatsappNo(value);
        if (e.type === 'blur') {
            setwhatsappNoError(!isValid);
        }
    };

    function updateUserProfile(e) {
        e.preventDefault();
        let isValid = validateForm();
        let postData = new FormData()
        let idUser = userData.data.id
        if (isValid) {
            postData.append('first_name', userName)
            postData.append('whatsapp_no', whatsappNo)
            postData.append('email', email)
            postData.append('location', location)
            if (avatarFile != undefined) {
                postData.append("avatar", avatarFile)
            }
            infoSnack(formSnackbar.PRE_USERHANDLE_UPDATE)
            ApiService.put('updateGeneralDetail', postData, { id: idUser }).then(function (response) {
                if (response.status === 200) {
                    successSnack(formSnackbar.POST_PROFILE_UPDATE)
                } else {
                    errorSnack(response.data.avatar[0])
                    // errorSnack(formSnackbar.serviceRequest)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    function userNameChangeHandler(e) {
        const { name, value } = e.target;

        if (name === 'userName') {
            setUserName(value);
        } else if (name === 'email') {
            setEmail(value);
        }

        if (e.type === 'blur') {
            if (name === 'userName') {
                const isValidName = value.length >= 3 && value.length < 50;
                setErrorName(!isValidName);
                setHelperTextName(isValidName ? null : 'Name must contain at least 3 characters and max up to 50 characters.');
                setSaveBtn(isValidName);
            } else if (name === 'email') {
                const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                const isValidEmail = mailformat.test(value);
                setEmailError(!isValidEmail);
                // setHelperTextEmail(isValidEmail ? null : 'Please enter a valid email address.');
            }
        }
    }


    return (
        <div>
            <div className='text-secondary'>
                <p className="mb-2">Add some basic information about yourself</p>
            </div>
            <div className='d-flex'>
                <div className="avatar-upload" title="Upload your image (Resolution: 250px*250px)">
                    <div className="avatar-edit">
                        <input type='file' onChange={handleFileSelect} id="imageUpload" accept=".png, .jpg, .jpeg" />
                        <label htmlFor="imageUpload">
                            <PhotoCameraIcon />
                        </label>
                    </div>
                    <div className="avatar-preview">
                        <div id="imagePreview" /* style="background-image: url('http://i.pravatar.cc/500?img=7');" */>
                            <img src={userThumbnai ? userThumbnai : userData == "" ? profileImge : process.env.REACT_APP_BASEURL_MEDIA + '/media/' + ''} className="h-100 rounded-circle w-100" onError={(e) => { e.target.src = profileImge }} />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        onClick: null, // Disable close on background click
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <div className='d-flex'>
                                <div>
                                    <Typography id="transition-modal-title" variant="h6" component="h2">Crop and Save Image</Typography>
                                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                        Adjust the crop area to select the desired portion of the image. Click the 'Save' button to save the cropped image.
                                    </Typography>
                                </div>
                                <div>
                                    <IconButton onClick={handleClose} aria-label="Close" color="secondary">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>

                            {previewImage && (
                                <div>
                                    <div className="m-2" style={{ width: '100%', maxWidth: '100%', backgroundColor: 'transparent', border: 'solid 2px', borderRadius: '8px', borderStyle: 'dashed', textAlign: 'center' }}>
                                        <AvatarEditor
                                            ref={editorRef}
                                            image={previewImage}
                                            width={xsSmScreen ? 190 : 230}
                                            height={xsSmScreen ? 190 : 230}
                                            border={50}
                                            color={[255, 255, 255, 0.6]}
                                            scale={scale}
                                            borderRadius={115}
                                            rotate={0}
                                            style={{ touchAction: "manipulation" }}
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex align-items-center'>
                                            <div style={{ width: '100px' }}>
                                                <Typography variant="body1"><ZoomInIcon /> Zoom In</Typography>
                                            </div>
                                            <div style={{ width: '10px' }}></div>
                                            <div style={{ flexGrow: '1', maxWidth: '12rem', marginTop: '5px' }}>
                                                <Slider
                                                    min={1}
                                                    max={2}
                                                    step={0.1}
                                                    value={scale}
                                                    onChange={handleScaleChange}
                                                />
                                            </div>
                                        </div>
                                        <button className='gn-actionbtn' onClick={handleCrop} ><CropIcon /> Crop</button>
                                    </div>
                                </div>
                            )}
                        </Box>
                    </Fade>
                </Modal>
            </div>

            {/* Image:end */}
            <div className='mt-4'>
                <TextField
                    label="Name"
                    id="outlined-size-small"
                    placeholder='Please enter your name'
                    onChange={userNameChangeHandler}
                    value={userName}
                    size="small"
                    sx={{ width: '100%' }}
                    name="userName"
                    color="secondary"
                    type="text"
                    error={errorName}
                    helperText={helperTextName}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    required
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Primary Phone No."
                    id="outlined-size-small"
                    placeholder='Primary phone no'
                    value={primaryNo}
                    size="small"
                    sx={{ width: '100%' }}
                    name="primaryNo"
                    color="secondary"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    disabled
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Whatsapp No."
                    id="outlined-size-small"
                    placeholder='Whatsapp no'
                    onChange={phoneChangeHandler}
                    onBlur={phoneChangeHandler}
                    value={whatsappNo}
                    size="small"
                    sx={{ width: '100%' }}
                    name="whatsappNo"
                    color="secondary"
                    type="number"
                    error={whatsappNoError}
                    helperText={whatsappNoError ? 'Please enter a valid 10-digit number.' : ''}
                    InputProps={{
                        inputProps: { maxLength: 10 },
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    required
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Email"
                    id="outlined-size-small"
                    placeholder='Please enter your email'
                    onChange={userNameChangeHandler}
                    onBlur={userNameChangeHandler}
                    value={email}
                    size="small"
                    sx={{ width: '100%' }}
                    name="email"
                    color="secondary"
                    type="text"
                    error={emailError}
                    helperText={emailError ? 'Email formate is not correct' : ''}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    required
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Location"
                    id="outlined-size-small"
                    placeholder='Please enter location'
                    onChange={(e) => setLocation(e.target.value)}
                    value={location}
                    size="small"
                    sx={{ width: '100%' }}
                    name="location"
                    color="secondary"
                    type="text"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    required
                />
            </div>
            <div className='mt-4'>
                <TextField
                    label="Agency ID"
                    id="outlined-size-small"
                    placeholder='Please enter agency ID'
                    value={agencyId}
                    size="small"
                    sx={{ width: '100%' }}
                    name="agencyId"
                    color="secondary"
                    type="text"
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                    }}
                    disabled
                />
            </div>
            <div className='mt-2 mb-5'>
                {<button title="Save" disabled={userName && email && !emailError && location && whatsappNo && !whatsappNoError && agencyId && saveBtn ? false : true} className='gn-actionbtn float-end' onClick={updateUserProfile}>Save</button>}
            </div>
        </div>
    );
}

export default General;