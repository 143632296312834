export const authApi = {
   LOGIN: process.env.REACT_APP_BASEURL + 'api/auth/login/',
}
export const logoutApi = {
   LOGOUT: process.env.REACT_APP_BASEURL + 'api/auth/logout/',

}
export const registerApi = {
   REGISTER: process.env.REACT_APP_BASEURL + 'api/auth/register/',
}
export const activateUserAccount = {
   GET: process.env.REACT_APP_BASEURL + 'user_auth/activate/',
}
export const resetPasswordApi = {
   RESET: process.env.REACT_APP_BASEURL + 'user_auth/passwordreset/',
   FORGETPASSWORD: process.env.REACT_APP_BASEURL + 'user_auth/reset-password',
}
export const ordersApi = {
   ORDERS: process.env.REACT_APP_BASEURL + 'api/order',
}
export const userInfoApi = {
   VIEW: process.env.REACT_APP_BASEURL + 'api/auth/userview',
}
export const loginUserApi = {
   VIEW: process.env.REACT_APP_BASEURL + 'api/auth/userview',
}
export const userTypeApi = {
   VIEW: process.env.REACT_APP_BASEURL + '/content_creator/userType/',
}
export const updateUserInfoApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'user_auth/users/edit',
}
export const userApi = {
   LIST: process.env.REACT_APP_BASEURL + 'api/v1/users',
}

// ___________________ PROFILE API'S________________________
export const userProfileApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'user_auth/users/edit',
   UPDATEPROFILE: process.env.REACT_APP_BASEURL + 'user_auth/user'
}

export const getLanguageApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/language/',
}
export const updateLanguageApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'api/auth/language/',
}
export const updateCategoryApi = {
   ADD: process.env.REACT_APP_BASEURL + 'api/auth/category/',
   UPDATE: process.env.REACT_APP_BASEURL + 'api/auth/category/'
}
export const getGenreApi = {
   GET: process.env.REACT_APP_BASEURL + 'api/auth/contenttype/'
}
export const updateGenreApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'api/auth/contenttype/',
}
export const getExpertiseApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/expertiseLevel/',
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/expertiseLevel'
}
export const getExperienceApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/experience/user',
   POST: process.env.REACT_APP_BASEURL + 'content_creator/experience',
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/experience',
}
export const deleteExperienceApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/experience',
}
export const userHandleApi = {
   GET: process.env.REACT_APP_BASEURL + 'user_auth/check-userHandle/',
}

export const userSocialTypeApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/social-type/'
}

export const userSocialLinkApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/social-link/user/'
}
export const userPostSocialLinkApi = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/social',
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/social',
}

export const userDeleteSocialLinkApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/social-link',
}
export const getCityApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/city/',
}
export const getCountryApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/country/',
}
export const getStateApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/state/country_code/',
}
export const getBannerApi = {
   GET: process.env.REACT_APP_BASEURL + 'user_auth/banner/',
}
export const createrLink = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/link/user/',
   POST: process.env.REACT_APP_BASEURL + 'content_creator/link'
}

export const getThumbnailLink = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/thumbnail/',
}
export const updateSequenceLink = {
   PUT: process.env.REACT_APP_BASEURL + 'content_creator/update-sequence/',
}

export const commonMusicLink = {
   GETALL: process.env.REACT_APP_BASEURL + 'content_creator/getSongList/',
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   GETSINGLE: process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   POST: process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   GETBYUSER: process.env.REACT_APP_BASEURL + 'content_creator/getsongList/user',
   SETSONGSEQUENCE: process.env.REACT_APP_BASEURL + 'content_creator/song-sequence/',

}

export const tagsListURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/tags/',
   POST: process.env.REACT_APP_BASEURL + 'content_creator/tags',
}

export const streamingPlatformURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/streamingPlatforms/',
}

export const songRelatedPlatformURL = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
   GETALL: process.env.REACT_APP_BASEURL + 'content_creator/streamingPlatforms/',
   GETBYSONG: process.env.REACT_APP_BASEURL + 'content_creator/getrelatedStreamingPlatforms/song',
   PUT: process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
}

export const verifyArtistURL = {
   POST: process.env.REACT_APP_GNADMINURL + 'user/artistVerificationDetails',
   PUT: process.env.REACT_APP_BASEURL + 'content_creator/verifiedUser',
}
export const artistEnquiryURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/enquiry/user/',
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/enquiry',
   POST: process.env.REACT_APP_BASEURL + 'content_creator/enquiry/delete_multiple/',


}

export const moodURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/mood/',
}
export const subgenreURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/sub_genre/',
}

export const recordLabelURL = {
   GET: process.env.REACT_APP_SONGAPIURL + '/releases/record_label/'
}

export const creatorUID = {
   VERIFY_CREATOR_UID: process.env.REACT_APP_BASEURL + 'content_creator/verifyuid/',

}
export const notificationsURL = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/notifications/',
   ALL: process.env.REACT_APP_BASEURL + 'content_creator/creator_notification',
   MARK_ALL_READ: process.env.REACT_APP_BASEURL + 'content_creator/creator_notification/mark_all_as_read/',
}
export const SearchStudio = {
   SEARCH: process.env.REACT_APP_STUDIOURL + 'content_creator/searchstudio_associates/',
}
export const SearchArtistList = {
   SEARCH: process.env.REACT_APP_STUDIOURL + 'content_creator/searchstudio_associatelist/',
}
export const SearchAllAssociateArtist = {
   SEARCH: process.env.REACT_APP_BASEURL + 'user_auth/showAllArtist/'
}
/* Playlist Module apis endpoints:start */
export const playistEndpoint = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/playlist',
   GETALL: process.env.REACT_APP_BASEURL + 'content_creator/playlist/',
   GETALLOFUSER: process.env.REACT_APP_BASEURL + 'content_creator/playlist/user',
   DELETE_PLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlists',
   GET_SINGLE_PLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlists',
   UPDATE_PLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlist',
   GET_ALL_SONGS_OF_USER: process.env.REACT_APP_BASEURL + 'content_creator/getsongList/user/',
   REMOVE_SONG_FROM_PLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlist/removesong/',
   SEARCH_PLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlist/',
   SONG_REORDER_INPLAYLIST: process.env.REACT_APP_BASEURL + 'content_creator/playlist-song-sequence/',
}
/* Playlist Module apis endpoints:end */

// --------- Meta Pixel apis endpoint star------------
export const metapixelEndpoint = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/meta_pixel',
   PUT: process.env.REACT_APP_BASEURL + 'content_creator/meta_pixel',
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/meta_pixel',
}
// --------- Meta Pixel apis endpoint end------------

// ---------------------platformSequence ----------------
export const platformUpdateSequence = {
   SETSONGSEQUENCE: process.env.REACT_APP_BASEURL + 'content_creator/platform-sequence/',
}

export const AlbumEndpoint = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummeta',
   GETALL: process.env.REACT_APP_BASEURL + 'content_creator/playlist/',
   GETALLOFUSER: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummeta/creator/',
   DELETE_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummetadelete/',
   // DELETE_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/playlists',
   GET_SINGLE_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummetaId/creator/',
   UPDATE_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummeta/',
   GET_ALL_SONGS_OF_USER: process.env.REACT_APP_BASEURL + 'content_creator/getsongList/user/',
   REMOVE_SONG_FROM_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/releases/albummeta/removesong/',
   // REMOVE_SONG_FROM_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/playlist/removesong/',
   SEARCH_ALBUM: process.env.REACT_APP_BASEURL + 'content_creator/playlist/',
   SONG_REORDER_INALBUM: process.env.REACT_APP_BASEURL + 'content_creator/releases/album-song-sequence/',
   // SONG_REORDER_INALBUM: process.env.REACT_APP_BASEURL + 'content_creator/playlist-song-sequence/',
   GET_ALBUM_SONG: process.env.REACT_APP_BASEURL_DEMO + "releases/albumsongs/creator/",
   READY_FOR_RELEASE: process.env.REACT_APP_BASEURL + 'user/albumApproval'
}

export const connectWithSpotifyArtist = {
   FETCH_SPOTIGY_ARTIST_DETAIL: process.env.REACT_APP_BASEURL_DEMO + 'fetch-artist-details/',
   FETCH_SPOTIFY_ARTIST_LIST: process.env.REACT_APP_BASEURL_DEMO + 'search-artists/',
}

