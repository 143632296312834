import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSnackDispatcher from '../../_helpers/reusablefunctions/snackBarComponent';
import ApiService from '../../_services/ApiService';
import { TextField } from '@mui/material';

function Genre(props) {
    const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
    const userData = useSelector((state) => state.userData.userData);
    const [userGenre, setUserGenre] = useState()
    const [genre, setGenre] = useState([])
    const [contentTypeChecked, setcontentTypeChecked] = useState(false)
    const [error, setError] = useState(false)
    const dispatch = useDispatch();

    useEffect(function () {
        setUserGenre(userData?.data?.userprofile?.contenttype || [])
    }, [userData]);

    function getcontentTypeList() {
        dispatch(setLoader(true))
        ApiService.get('getupdateCreate',{},'contenttype/').then(function (response) {
            dispatch(setLoader(false))
            setGenre(response.data.data)
        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })
    }

    useEffect(function () {
        getcontentTypeList()
    }, []);

    function UpdateGenrecontentType(postData) {
        dispatch(setLoader(true))
        infoSnack(formSnackbar.PRE_GENRE_UPDATE)
        ApiService.put('getupdateCreate', postData, null, userData?.data?.id).then(function (response) {
            if (response.status === 200) {
                successSnack(formSnackbar.POST_GENRE_UPDATE)
                dispatch(setLoader(false))
            }
            else {
                dispatch(setLoader(false))
                errorSnack(formSnackbar.serviceRequest.serviceRequestFailure)
            }
        })
    }

    function onChangeGenreHandler(e, data) {
        var postData = new FormData()
        if (userGenre.find(d => e.target.id == d)) {
            let currentSelected = userGenre.filter(r => r != e.target.id);
            setUserGenre([...currentSelected])
            let selectedIds = currentSelected ? currentSelected.length > 0 ? currentSelected.map(obj => obj) : [] : []
            postData.append('contenttype_ids', selectedIds.join())
            UpdateGenrecontentType(postData)
        } else {
            setUserGenre([...userGenre, data.id])
            let selectedIds = [];
            selectedIds = userGenre ? userGenre.length > 0 ? userGenre.map(obj => obj) : [] : []
            selectedIds = [...selectedIds, data.id]
            postData.append('contenttype_ids', selectedIds.join(','))
            UpdateGenrecontentType(postData)
        }
    }

    function CreateUsercontentType(postData) {
        infoSnack('Content Type creating...')
        ApiService.post('getupdateCreate', postData, 'contenttype').then(function (response) {
            if (response.status === 200) {
                setcontentTypeChecked(false)
                getcontentTypeList()
                successSnack('contentType created successfully.')
            }
            else {
                errorSnack(response?.data?.ContentTypeName[0])
            }
        })
    }

    function onChangecontentType(e) {
        const { value } = e.target;
        var postData = new FormData()
        if (e.type == 'blur') {
            if (value.length > 3) {
                setError(false)
                postData.append('ContentTypeName', value)
                CreateUsercontentType(postData)
            } else {
                setError(true)
            }
        }
    }

    return (
        <div>
            <div className='text-secondary'>
                <p>Choose the revelent campaign type</p>
            </div>
            <div className='py-1 row px-4'>
                {genre ? genre.map(d => (
                    <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id} >
                        <div className="form-check-inline d-inline-flex">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => onChangeGenreHandler(e, d)}
                                        id={d.id.toString()}
                                        name={d.id.toString()}
                                        checked={userGenre ? userGenre.filter(v => v == d.id).length ? true : false : false}
                                    />}
                                    label={d.ContentTypeName} />
                            </FormGroup>
                        </div>
                    </div>
                )) : []}
                <div className="col-md-4 col-sm-12 col-lg-3 p-0 ">
                    <div className="form-check-inline d-inline-flex">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox
                                    onChange={(e) => setcontentTypeChecked(e.target.checked)}
                                    id='other-contentType'
                                    name='othercontentType'
                                    checked={contentTypeChecked}
                                />}
                                label='Other' />

                        </FormGroup>
                    </div>
                </div>
                {contentTypeChecked && <div className='p-0 col-md-12 col-sm-12 col-lg-12 mt-3'>
                    <TextField
                        label="Content Type"
                        id="outlined-size-small"
                        placeholder='Please enter contentType'
                        onChange={onChangecontentType}
                        onBlur={onChangecontentType}
                        size="small"
                        sx={{ width: '100%' }}
                        name="contenttype"
                        color="secondary"
                        type="text"
                        error={error}
                        helperText={error && "Enter at least 4 characters."}
                    />
                </div>}
            </div>
        </div>
    );
}

export default Genre;