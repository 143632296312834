import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { formSnackbar } from "../_services/_constants/snackbar.constant";
import { setLoader, setSnackBar } from "../redux";
import SnackbarContainer from "../module/Snackbar";
import './style.css'
import PhoneInput from "react-phone-input-2";
import backgroundImage from '../img/bg_image_texture3.png'
import {
  Box, TextField, IconButton, Modal, Skeleton
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ApiService from "../_services/ApiService";
import useSnackDispatcher from "../_helpers/reusablefunctions/snackBarComponent";
import { RegisterUser } from "../_services/register/RegisterUser";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3,
};
function Register(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const { successSnack, errorSnack, warningSnack, infoSnack } = useSnackDispatcher();
  const [showConPassword, setShowConPassword] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [contactNumber, setContactNumber] = useState("")
  const [gnService, setGnService] = useState(false);
  const [gnTerm, setGnTerm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setC_Password] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");
  const [c_passErr, setC_PassErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const userRole = [{ value: 'nano', label: 'Nano' }, { value: 'macro', label: 'Macro' }, { value: 'micro', label: 'Micro' }]
  const [userTypeId, setUserTypeId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [open, setOpen] = React.useState(false);
  const [artistTypeError, setArtistTypeError] = useState(null)
  const LogoApp = process.env.REACT_APP_APPURL == 'https://creator.groovenexus.com' ? logoCreators : logoGntunes
  const handleClose = () => setOpen(false);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  var regexp =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!#$@^%&? "])(?=.*[@$!%*?&])[a-zA-Z0-9!#$@^%&?]{8,20}$/
  const phoneChangeHandler = (phone) => {
    if (phone.length < 12) {
      setPhoneError(
        <span className="text-danger small">
          Please fill the country code with phone number
        </span>
      );
      setContactNumber(phone)
    } else {
      setContactNumber(phone)
      setPhoneError('')
    }
  }
  const formInputChangeHandler = (e) => {
    const { name, value, id } = e.target;

    const handleValidation = (isValid, setter, errorSetter, errorMessage) => {
      setter(value);
      errorSetter(isValid ? "" : <span className="text-danger small">{errorMessage}</span>);
    };

    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "name":
        handleValidation(
          /^[a-zA-Z ]+$/.test(value.trim()),
          setName,
          setNameError,
          "Please enter a valid name (only alphabets allowed)"
        );
        break;
      case "contactNumber":
        setContactNumber(value);
        setPhoneError("");
        break;
      case "password":
        setPassword(value);
        break;
      case "c_password":
        setC_Password(value);
        handleValidation(
          password === value,
          () => { },
          setC_PassErr,
          "Passwords do not match"
        );
        break;
      case "userRole":
        setUserTypeId(id);
        break;
      default:
        break;
    }
  };


  const submitClickHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (mailformat.test(email)) {
      formData.append('email ',email);
      setEmailErr("");
    } else {
      setEmailErr(
        <div className="form-group">
          <span className="text-danger small">Please enter valid email</span>
        </div>
      );
    }
    if (name) {
      formData.append('first_name ',name);
      setNameError("");
    } else {
      setNameError(
        <span className="text-danger small">
          Please fill the required field
        </span>
      );
    }
    if (contactNumber) {
      formData.append('phone_number ',contactNumber);
      setPhoneError("");
    }
    else {
      setPhoneError(
        <span className="text-danger small">
          Please fill the required field
        </span>
      );
    }
    if (userTypeId) {
      formData.append('role ',userTypeId);
      setArtistTypeError("");
    }
    else {
      setArtistTypeError(
        <div className="form-group">
          <span className="text-danger small">
            Please select your primary role.
          </span>
        </div>)
    }
    if (password.match(regexp)) {
      formData.append('password ',password);
      setPassErr("");
    }
    else if (password.length === 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            password is required{" "}
          </span>
        </div>
      );
    }
    else if (password.length < 8) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must be at least 8 characters{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[a-z]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one lower case letter{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[A-Z]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one upper case letter{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[0-9]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least one digit.{" "}
          </span>
        </div>
      );
    }
    else if (password.search(/[!@#\$%\^&\*_]/) < 0) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Your password must contain at least special char from -[ ! @ # $ % ^ & * _ ]{" "}
          </span>
        </div>
      );
    }
    else if (password.includes(" ")) {
      setPassErr(
        <div className="form-group">
          <span className="text-danger small">
            Password cannot contain white spaces.
          </span>
        </div>);
    }

    if (c_password === password) {
      setC_PassErr("");
    } else {
      setC_PassErr(
        <div className="form-group">
          <span className="text-danger small">
            Please enter valid confirm password
          </span>
        </div>
      );
    }
    if (
      mailformat.test(email) &&
      c_password === password &&
      (password.match(regexp) && gnService) && userTypeId && contactNumber
    ) {
      formData.append('agree_with_gnservices ',gnService)
      formData.append('terms_and_conditions ',gnTerm)

      infoSnack(formSnackbar.PRE_REGISTER_MSG)
      ApiService.post('register', formData).then((response) => {
        dispatch(setLoader(false));
        if (response.status === 200) {
          successSnack(formSnackbar.POST_REGISTER_MSG)
          setTimeout(() => history.push("/thank-you"), 1500);
        } else {
          const errorMessage = response.data.email?.length > 0
            ? formSnackbar.errors.alreadyRegistered
            : formSnackbar.errors.errorMsg;
          errorSnack(errorMessage)
        }
      })
        .catch((error) => 
          errorSnack(error)
      );

    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setShowConPassword(!showConPassword);
  };

  return (
    <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <div className="row justify-content-center">
        <nav className="navbar navbar-light  border pb-0">
          <div className="container-fluid">
            <a className="navbar-brand p-2" href="https://gntunes.com/">
              <img src={LogoApp} alt="" />
            </a>
          </div>
        </nav>
        <div className="col-md-9 col-lg-9 col-xl-6 col-sm-12 pb-5">
          <div className="login-form  mt-4 p-4 border bg-white" >
            <form action="" method="" className="row g-3">
              <h5 className=" cardheading mt-3">
                Get Started With Groovenexus
              </h5>
              <div>
                <TextField
                  fullWidth
                  size="small"
                  id="outlined-basic"
                  label="Full Name *"
                  variant="outlined"
                  placeholder="Full Name"
                  type="text"
                  name="name"
                  className=""
                  onChange={formInputChangeHandler}
                />
                {nameError}
              </div>
              <div className="col-md-6">
                <TextField
                  size="small"
                  id="outlined-basic"
                  label="Email *"
                  variant="outlined"
                  placeholder="Email Address"
                  type="text"
                  name="email"
                  className=" w-100"
                  onChange={formInputChangeHandler}
                />
                {emailErr}
              </div>

              <div className="col-md-6">
                <PhoneInput
                  inputProps={{
                    required: true
                  }}
                  className="addStrik"
                  country={'in'}
                  value={contactNumber}
                  name="contactNumber"
                  placeholder=""
                  onChange={phoneChangeHandler}
                />
                {phoneError}
              </div>
              <div className="col-md-12">
                <TextField
                  label="Password *"
                  size="small"
                  name="password"
                  placeholder="Password"
                  className=" w-100"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  value={password}
                  onChange={formInputChangeHandler}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  helperText='Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character.'
                />
                {passErr}
              </div>
              <div className="col-md-12">
                <TextField
                  label="Confirm Password *"
                  size="small"
                  name="c_password"
                  placeholder="Confirm Password"
                  className=" w-100"
                  type={showConPassword ? "text" : "password"}
                  variant="outlined"
                  value={c_password}
                  onChange={formInputChangeHandler}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickConfirmShowPassword}>
                        {showConPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                {c_passErr}
              </div>
              <div className="col-md-12 my-3">
                <div className="mb-2"><label>Your Primary Role *</label></div>
                {userRole ? (
                  userRole.map((data, index) => (
                    <div
                      key={index}
                      className="btn-group col-md-4 col-sm-12 col-12 col-lg-4 text-white p-0 pb-2"
                      role="group"
                      aria-label="Basic radio toggle button group"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="userRole"
                        id={data.value}
                        onChange={formInputChangeHandler}
                      />
                      <label
                        className={`${userTypeId == data.value ? 'backgroundColor text-white' : 'colorText '} btn borderColor rounded-0 overflow-hidden text-truncate`}
                        htmlFor={data.value}
                      >
                        <b>{data.label}</b>
                      </label>
                    </div>
                  ))
                ) : (
                  <Skeleton height={60} animation="wave" />
                )}
                {artistTypeError}
              </div>
              {errorMsg}
              <div className="col-md-12 m-0">
                <div className="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    sx={{
                      color: "red !im",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    className="form-check-input  checkbox"
                    id="exampleCheck1"
                    onClick={(e) => setGnTerm(prevvalue => !prevvalue)}
                  />
                  <label
                    className="form-check-label ps-2 mt-md-1 w-70"
                    htmlFor="exampleCheck1"
                  >
                    I have read the <span className="fw-bold text-decoration-underline colorText" >Terms and Conditions </span>
                  </label>
                </div>
              </div>
              <div className="col-md-12 m-0">
                <div className="form-check d-flex align-items-center">
                  <input
                    type="checkbox"
                    sx={{
                      color: "red !im",
                      "&.Mui-checked": {
                        color: "red",
                      },
                    }}
                    className="form-check-input  checkbox"
                    id="exampleCheck1"
                    onClick={(e) => setGnService(prevvalue => !prevvalue)}
                  />
                  <label
                    className="form-check-label ps-2 mt-md-1 w-70"
                    htmlFor="exampleCheck1"
                  >
                    Yes, I understand and agree to gntunes Services
                  </label>
                </div>
              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <h6>Thanku for Register</h6>
                </Box>
              </Modal>
              <div className="xs-mb-2 fs-09 align-items-center align-items-lg-baseline align-items-md-center col-12 col-md-12 col-sm-12 d-flex d-lg-inline-block d-md-inline-block d-sm-flex flex-column flex-lg-row flex-md-column flex-sm-column">
                <span>
                  Already have account ?{" "}
                  <Link to="/" className="text-decoration-none colorText">
                    <strong>Login</strong>
                  </Link>
                </span>
                <button
                  type="submit"
                  className="gn-actionbtn float-end  mt-sm-3 mt-3 mt-lg-0 mt-md-0"
                  onClick={(e) => submitClickHandler(e)}
                  disabled={mailformat.test(email) && /^[a-zA-Z ]+$/.test(name) &&
                    c_password === password &&
                    (password.match(regexp) && gnService && gnTerm) && userTypeId && contactNumber.length === 12 && gnService && gnTerm ? false : true}
                >
                  SIGN UP
                </button>
                <SnackbarContainer />
              </div>
            </form>
          </div>
        </div>
      </div >
      <Footer />
    </div >
  );
}

export default Register;
