import React, { useState } from 'react';
import { Email } from '@mui/icons-material'
import { Lock } from '@mui/icons-material'
import { useHistory, useLocation, Redirect, useParams } from 'react-router-dom';
import logoCreators from "../img/logoSmall2.png";
import logoGntunes from "../img/logoGntunes.svg";
import Footer from './footer';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import google from '../img/google.svg'
import { login } from '../_services/auth/auth.service';
import { resetPassword } from "../_services/resetPassword"
import {
    setSsoToken,
    setSelfToken,
} from '../_helpers/auth/secureToken';
import { setLoader } from '../redux';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { TextField } from '@mui/material';
import backgroundImage from '../img/bg_image_texture3.png'
import LogoApp from '../img/Gn-Creator-logo.svg'


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: 3,
    boxShadow: 24,
    p: 3
};
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    let { rowId, token } = useParams();
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [conPassError, setConPassError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [modalText, setModalText] = useState('')
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var regexp = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (password.match(regexp) || format.test(password)) {
            data.append('new_password', password);
            setPassError("")
        } else {
            setPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid password </span>
            )
        }
        if (conPassword.match(password)) {
            setConPassError("")
        } else {
            setConPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid confirm password </span>
            )
        }
        if (password.match(regexp) || format.test(password) && conPassword.match(password)) {
            dispatch(setLoader(true))
            resetPassword(data, rowId + '/' + token)
                .then(function (response) {
                    if (response.status === 200) {
                        dispatch(setLoader(false))
                        setModalText('Password has been reset successfully.')
                        handleOpen();
                        setTimeout(() => { history.push('/login') }, 3000)
                    }
                    else {
                        setModalText(`There is an error. ${response.data.error[0]}`)
                        dispatch(setLoader(false))
                        handleOpen();
                    }
                }).catch(function (error) {
                    dispatch(setLoader(false))
                    console.log(error.response);
                    return error.response;
                })
        }
    }

    return (
        <div className="container-fluid " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <div className="row">
                <nav className="navbar navbar-light  border pb-0 ">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src={LogoApp} alt="logo" className="w-100" /></a>
                    </div>
                </nav>
                <div className='' style={{ maxWidth: '600px', width: '100%', margin: 'auto' }}>
                    <div className="login-form my-5 px-4 pb-4 border bg-white">
                        <form action="" method="" className="row g-3">
                            <h5 className=" fw-400 text-secondary mt-4">
                                Enter the new password
                            </h5>
                            <div className="col-md-12">
                                <input type="hidden" name="token" value="{{ $token }}" />
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        label="Password*"
                                        size='small'
                                        variant="outlined"
                                        type={showPassword ? "text" : "password"}
                                        className="w-100"
                                        placeholder=" Password *"
                                        name="password"
                                        onChange={e => { setPassword(e.target.value); setPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        helperText='Password must contain at least 8 characters, including at least 1 upper case letter, 1 lower case letter,1 number and 1 special character.'
                                    />
                                </div>

                                {passError}
                            </div>
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label="Confirm Password*"
                                    variant="outlined"
                                    type={showConPassword ? "text" : "password"}
                                    className="w-100"
                                    placeholder=" Confirm  Password *"
                                    name="password"
                                    size='small'
                                    sx={{ marginBottom: '1rem' }}
                                    onChange={e => { setConPassword(e.target.value); setConPassError("") }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => setShowConPassword(!showConPassword)}
                                                edge="end"
                                            >
                                                {showConPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {conPassError}
                            </div>

                            <div className="col-md-12 m-0">
                                <button type="submit" className="gn-actionbtn float-end py-2" onClick={onClickHandler}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h6>{modalText}.</h6>
                </Box>
            </Modal>
        </div>
    );
}



export default ResetPassword;