import React from 'react'
import ThankYou from '../img/general/Thankyou.svg'
import backgroundImage from '../img/background/bg_image_texture6.png'
import logo from '../img/Gn-Creator-logo.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom'
import axios from 'axios';

const SingupVerification = () => {
  return (
    <div className="container-fluid  " style={{ height: '100vh', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', backgroundColor:'#ffffff' }}>
      <div className="row">
        <nav className="navbar navbar-light  border pb-0">
          <div className="container-fluid">
            <a className="navbar-brand" href="https://gntunes.com/">
              <img src={logo} alt="logo" className="w-100" />
            </a>
          </div>
        </nav>
        <div className="d-flex justify-content-start flex-column fullPage mt-5 ms-5">
          <div className='mt-5'>
            <img src={ThankYou} alt="Img Not found" className='w-25' />
            <div className="ms-5">
              <h1 className='mt-4' style={{ fontWeight: '900' }}>For Signing up, you're almost done</h1>
              <div className="m-4"></div>
              <h3 className='mt-4'>We've sent you an email, check your email inbox in few minutes.</h3>
              <h5 className='mb-4' style={{ color: '#3333337a', fontWeight: '500' }}>Please confirm your account by clicking the link in the email.</h5>
              <Link to="/"><button className='gn-actionbtn mt-4 mb-4'><ArrowBackIcon /> Back To Home Page</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingupVerification