import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Category from './category';
import Genre from './genre';
import Phone from './phone';
import General from './general';
import Language from './language';
import { useSelector, useDispatch } from 'react-redux'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import { fetchUserDetail } from '../../redux/user/userDataActions';
import SnackbarContainer from '../Snackbar';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function Profile(props) {
    const [type, setType] = useState("category")
    const [active, setActive] = useState("6")
    let { childPath } = useParams();
    const dispatch = useDispatch();
    const profileTabs =[
        {
            id: 6,
            tabActiveId: "6",
            tabTitle: 'General',
            tabType: 'general'
        },
        {
            id: 1,
            tabActiveId: "1",
            tabTitle: 'Category',
            tabType: 'category'
        },
        {
            id: 2,
            tabActiveId: "2",
            tabTitle: 'Content Type',
            tabType: 'contentType'
        },
        {
            id: 3,
            tabActiveId: "3",
            tabTitle: 'Language',
            tabType: 'language'
        },
    ]
   
    useEffect(function () {
        dispatch(fetchUserDetail());
        let currentURL = window.location.href.split('/profile?user=')[1]
        if (currentURL === undefined)
            currentURL = window.location.href.split('/?user=')[1]
        const currentTab = profileTabs.find(item => item.tabType === currentURL)
        currentTab && setActive(currentTab.tabActiveId)
    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:1100px)');
    const tabStyle = {
        bgcolor: 'background.paper',
        borderRadius: '10px',
        margin: mediumViewport ? '4px' : '10px 0px 10px 0px',
        // padding:'20px'
    }
    return (
        <>
            <div className='p-4'>
                <h1 className='fs-2'>Profile</h1>
                <div className="d-flex main-profile-container mb-5">
                    <div className="profile-tabs">
                        <Box sx={tabStyle}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                orientation={mediumViewport ? "vertical" : "horizontal"}
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#dc3545', // Custom color
                                    },
                                }}
                            >
                                {profileTabs ? profileTabs.map(item => {
                                    return (
                                        <Tab
                                            key={item.id}
                                            component={Link}
                                            to={`?user=${item.tabType}`}
                                            label={item.tabTitle}
                                            onClick={() => { setType(item.tabType); setActive(item.tabActiveId) }}
                                            className={`align-items-start ${active == item.tabActiveId ? "text-danger fw-bold" : "text-secondary"}`}
                                        />
                                    )
                                }) : null}
                            </Tabs>
                        </Box>
                    </div>
                    <div className="probile-tab-content">
                        <Box sx={{ minHeight: '350px', bgcolor: 'background.paper', borderRadius: '10px', margin: '5px 0px', padding: '20px' }}>
                            {(() => {
                                switch (window.location.search) {
                                    case '?user=category':
                                        return <Category />;
                                    case '?user=phone':
                                        return <Phone />;
                                    case '?user=general':
                                        return <General />;
                                    case '?user=language':
                                        return <Language />;
                                    case '?user=contentType':
                                        return <Genre />;
                                    default:
                                        return <General />
                                }
                            })()}
                        </Box>
                    </div>
                </div>
            </div>
            <SnackbarContainer />
        </>
    );
}

export default Profile;