import { useDispatch } from "react-redux";
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from "../../redux";

// Reusable custom hook for snack bar dispatch
const useSnackDispatcher = () => {
    const dispatch = useDispatch();

    const snackDispatcher = (message, variant, duration = 5000) => {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(message));
        dispatch(setSnackBarVariant(variant));
        setTimeout(() => dispatch(setSnackBar(false)), duration);
    };

    const successSnack = (message) => {
        snackDispatcher(message, 'success');
    };

    const errorSnack = (message) => {
        snackDispatcher(message, 'error');
    };

    const warningSnack = (message) => {
        snackDispatcher(message, 'warning');
    };

    const infoSnack = (message) => {
        snackDispatcher(message, 'info');
    };

    return { successSnack, errorSnack, warningSnack, infoSnack };
};

export default useSnackDispatcher;
