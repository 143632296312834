import React, { useState } from 'react';
import {
    Box, Card, CardContent, Typography, Tabs, Tab, Grid, Paper,
    CardMedia,
    TablePagination
} from '@mui/material';
import dashboardImg from '../../img/background/dashboardImg.png'
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import ApiService from '../../_services/ApiService';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import NoResults from '../../_helpers/reusablefunctions/NoResults';
import SkeletonCard from '../../_helpers/reusablefunctions/SkeletonCard';
const tabList = [
    { value: 0, label: 'NEW CAMPAIGN' },
    { value: 1, label: 'APPLIED' },
    { value: 2, label: 'SELECTED' },
    { value: 3, label: 'SUBMITTED' },
    { value: 4, label: 'CLOSED' },
    { value: 5, label: 'REJECTED' },
]


const CampaignTabs = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [campaigns, setCampaigns] = useState([]);
    const [loader, setLoader] = useState(true);
    const history = useHistory()

    const campaignListCall = (value) => {
        let payload = {
            status: value
        }
        ApiService.get('getCampaignList', payload).then(res => {
            setLoader(false)
            if (res.data.status == 200) {
                setCampaigns(res.data.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        campaignListCall('new')
    }, [])

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
        let value;
        if (newIndex === 0) {
            value = 'new';
        } else if (newIndex === 1) {
            value = 'applied';
        } else if (newIndex === 2) {
            value = 'selected';
        } else if (newIndex === 3) {
            value = 'submitted';
        } else if (newIndex === 4) {
            value = 'closed';
        } else if (newIndex === 5) {
            value = 'rejected';
        }
        setCampaigns([])
        campaignListCall(value);
    };

    const openDetail = () => {
        history.push('/campaign-details')
    }

    const CampaignCard = ({ campaign }) => (
        <Card sx={{ marginBottom: 2, boxShadow: '0px 1px 2px 0px #00000026', backgroundColor: '#F6F6F6', border: 'none', borderRadius: '10px', cursor: 'pointer' }}>
            <Grid container padding={'16px'}  >
                <Grid item xs={4}>
                    <CardMedia
                        component="img"
                        image={dashboardImg}
                        alt="campaign image"
                        sx={{ width: 64, height: 64, borderRadius: '10px' }}
                    />
                </Grid>
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Box>
                        <Typography variant="body2" color="text.secondary" fontSize={'12px'} textAlign={'center'}><strong>Price</strong></Typography>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}><CurrencyRupeeIcon fontSize='12px' />{campaign?.Budget || '-'}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <CardContent sx={{ paddingTop: 0 }}>
                <Typography variant="subtitle1" sx={{ color: '#FF4D49', fontWeight: 'bold', fontSize: '11px' }}>
                    GrooveNexus
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1, fontSize: '12px' }}>
                    {campaign.SongName}
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary" fontSize={'12px'}><strong>Start Date</strong></Typography>
                        <Typography variant="body2" fontSize={'12px'}><strong>{campaign.StartDate}</strong></Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary" fontSize={'12px'}><strong>End Date</strong></Typography>
                        <Typography variant="body2" fontSize={'12px'}><strong>{campaign.EndDate}</strong></Typography>
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: 1 }}>
                    <Typography variant="body2" color="text.secondary" fontSize={'12px'}><strong>Campaign Type</strong></Typography>
                    <Typography variant="body2" fontSize={'12px'}><strong>{campaign?.type || '-'}</strong></Typography>
                </Box>

                <Box sx={{ marginTop: 1, borderTop: '1px solid #0000001F', paddingTop: 1 }}>
                    <Typography variant="body2" color="text.secondary" fontSize={'12px'}><strong>Platform</strong></Typography>
                    <Typography variant="body2" fontSize={'12px'}><strong>Platform Youtube & Instagram</strong></Typography>
                </Box>
            </CardContent>
        </Card>
    );


    return (
        <Box sx={{ width: '100%' }}>
            {/* Header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                <Typography variant="h5">Campaign</Typography>
            </Box>
            <Paper>

                {/* Tabs */}
                <Tabs value={tabIndex}
                    onChange={handleTabChange}
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#dc3545', // Custom color
                        },
                    }}
                >
                    {tabList.map(d => (
                        <Tab key={d.value} label={`${d.label} (${campaigns.length})`} sx={{ color: tabIndex == d.value ? '#dc3545 !important' : '#4C4E64AD' }} />
                    ))}

                </Tabs>

                {/* Tab Content */}
                <Box sx={{ padding: 3 }}>
                    <Grid container spacing={2}>
                        {loader ?
                            Array.from(new Array(8)).map((item, index) => (<Grid key={index} item xs={3}>
                                <SkeletonCard />
                            </Grid>))
                            :
                            loader === false && campaigns.length === 0 ? (
                                <NoResults />
                            )
                                :
                                campaigns.map((campaign, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={() => openDetail()}>
                                        <CampaignCard campaign={campaign} />
                                    </Grid>
                                ))}
                    </Grid>
                </Box>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    colSpan={3}
                    sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                    className="cardPagination"
                    component="div"
                    count={campaigns.length}
                    rowsPerPage={5}
                    page={page}
                    // onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                />
                {/* Pagination */}
            </Paper>
        </Box>
    );
};

export default function Campaigns() {
    return (
        <Box sx={{ padding: '0px 24px' }}>
            <CampaignTabs />
        </Box>
    );
}
