import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {

      register: 'api/auth/register/',
      loginApi: 'api/auth/login/',
      updateGeneralDetail: 'api/auth/user',
      getCampaignList: 'api/auth/reelcampaign/',
      // getupdateCategory: 'api/auth',
      getupdateLanguage: 'api/auth/language/',
      // getupdateContentType: 'api/auth',
      getupdateCreate: 'api/auth',

      // -------------bankdetails -----------
      getpostBankDetails: 'api/auth/bankdetails/',
      deleteBankDetails: 'api/auth/bankdetails',
      // --------------instagramdetail------------
      getpostinstagramDetails: 'api/auth/instagram/',
      deleteinstagramDetails: 'api/auth/instagram',

       // --------------instagramdetail------------
       getpostyoutubeDetails: 'api/auth/youtube/',
       deleteyoutubeDetails: 'api/auth/youtube',
      
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Token ' + getSelfToken() },
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      let response
      if(endpointKey == "register"){ 
       response = await this.api.post(endpoint, data, {
      });
    }else{
      response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Token ' + getSelfToken() }
      })
    }
      return response;
    } catch (error) {
      // throw new Error('API request failed:', { error });
      return error.response;
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        data: params,
        headers: { Authorization: 'Token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null, pathParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (pathParam) {
        if (endpoint.endsWith('/')) {
          endpoint = `${endpoint}${pathParam}/`;
        } else {
          endpoint = `${endpoint}/${pathParam}/`;
        }
      }
      if (searchParam) {
        const searchParams = new URLSearchParams(searchParam).toString();
        endpoint = `${endpoint}?${searchParams}`;
      }
  
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'token ' + getSelfToken() }
      });
      
      return response;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  }
  

}



export default new APIService(BASE_URL);
